import { alpha, Theme, ThemeOptions } from '@mui/material';
declare module '@mui/material/styles' {
  interface TypographyVariants {
    maintenance?: React.CSSProperties;
    projectsMainDashboardHeader?: React.CSSProperties;
    projectCardSubtitle?: React.CSSProperties;
    projectCardTitle?: React.CSSProperties;
    toolmenu?: React.CSSProperties;
    sidePanelLogs?: React.CSSProperties;
    sidePanelLogsMoreInfo?: React.CSSProperties;
    dynamicValue?: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    maintenance?: React.CSSProperties;
    projectsMainDashboardHeader?: React.CSSProperties;
    projectCardSubtitle?: React.CSSProperties;
    projectCardTitle?: React.CSSProperties;
    toolmenu?: React.CSSProperties;
    sidePanelLogs?: React.CSSProperties;
    sidePanelLogsMoreInfo?: React.CSSProperties;
    dynamicValue?: React.CSSProperties;
  }

  interface Theme {
    header?: {
      height?: string;
      iconSize?: {
        sm: string;
        md: string;
        lg: string;
      };
      titleSize?: {
        sm: string;
        md: string;
        lg: string;
      };
      textSize?: {
        sm: string;
        md: string;
        lg: string;
      };
      iconFontSize?: {
        sm: string;
        md: string;
        lg: string;
      };
    };
  }

  interface ThemeOptions {
    header?: {
      height?: string;
      iconSize?: {
        sm: string;
        md: string;
        lg: string;
      };
      titleSize?: {
        sm: string;
        md: string;
        lg: string;
      };
      textSize?: {
        sm: string;
        md: string;
        lg: string;
      };
      iconFontSize?: {
        sm: string;
        md: string;
        lg: string;
      };
    };
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    maintenance: true;
    projectsMainDashboardHeader: true;
    projectCardSubtitle: true;
    projectCardTitle: true;
    toolmenu: true;
    sidePanelLogs: true;
    sidePanelLogsMoreInfo: true;
    depotDataSmall: true;
    depotDataLarge: true;
    popupTitle: true;
    dynamicValue: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    projectScreensNavMenu: true;
  }
}

export const baseStyles: ThemeOptions = {
  header: {
    height: '60px',
    textSize: {
      sm: '11px',
      md: '13px',
      lg: '17px',
    },
    titleSize: {
      sm: '16px',
      md: '19px',
      lg: '22px',
    },
    iconSize: {
      sm: '20px',
      md: '25px',
      lg: '30px',
    },
    iconFontSize: {
      sm: '12px',
      md: '17px',
      lg: '20px',
    },
  },

  typography: {
    fontFamily: 'Urbanist, Arial, sans-serif',
    fontSize: 16,
    h5: {
      fontSize: 24,
      lineHeight: '32px',
      fontWeight: 400,
      fontFamily: 'Urbanist, Arial, sans-serif',
    },
    button: {
      fontSize: 13,
      lineHeight: '22px',
      letterSpacing: '0.46px',
      fontWeight: 500,
      fontFamily: 'Urbanist, Arial, sans-serif',
    },
    maintenance: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 600,
      fontFamily: 'Urbanist, Arial, sans-serif',
    },
  },

  components: {
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          fontFamily: 'Urbanist',
          fontSize: '16px',
        },
        root: {
          '& .MuiAutocomplete-endAdornment': {
            top: 'auto',
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: () => ({
          fontSize: '16px',
          fontFamily: 'Urbanist',
          justifyContent: 'center',
          alignItems: 'center',
        }),
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          '&.MuiAvatarGroup-avatar': {
            backgroundColor: theme.palette.background.default,
            border: `${alpha(theme.palette.primary.main, 0.5)} solid 1.5px`,
            '&.MuiAvatar-colorDefault': {
              backgroundColor: theme.palette.accent.gray.dark,
              border: `${theme.palette.background.default} solid 1.5px`,
            },
          },
          '&.project-logo-avatar': {
            padding: '4px',
          },
        }),
        img: {
          // handle correctly non-square images
          objectFit: 'cover',
          height: '85%',
          width: 'fit-content',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          '&.project-card-menu-options': {
            '& ul': {
              borderTop: `${theme.palette.accent.secondary.main} 4px solid`,
              minWidth: '180px',
              padding: 0,
              '& .MuiDivider-root': {
                margin: 0,
                padding: 0,
              },
            },
            '& li': {
              '&:hover': {
                backgroundColor: alpha(theme.palette.accent.secondary.main, 0.08),
              },
            },
          },
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          fontFamily: 'Urbanist, Arial, sans-serif',
          height: '35px',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          '& .Mui-checked .MuiSwitch-thumb': {
            backgroundColor: theme.palette.accent.primary.main,
          },
          '& .Mui-checked.Mui-disabled .MuiSwitch-thumb': {
            backgroundColor: theme.palette.accent.primary[100],
          },
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          height: '100%',
          gap: '9px',
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: '4px',
          padding: '8px 16px',
          '& .MuiTypography-root': {
            whiteSpace: 'nowrap',
          },
          transition: 'background-color 0.2s ease-in-out',
          '&:hover': {
            backgroundColor: alpha(theme.palette.accent.primary.main, 0.5),
            // color: theme.palette.accent.primary.main,
          },
        }),
      },
      variants: [
        {
          props: { variant: 'contained' },
          style: ({ theme }: { theme: Theme }) => ({
            backgroundColor: theme.palette.accent.primary[500],
            fontWeight: 600,
            color: theme.palette.primary.main,
          }),
        },
        {
          props: { variant: 'projectScreensNavMenu' },
          style: ({ theme }: { theme: Theme }) => ({
            border: 'none',
            '& .arrow-icon': {
              color: alpha(theme.palette.primary.main, 0.55),
            },
            '&:hover': {
              backgroundColor: alpha(theme.palette.accent.primary.main, 0.12),
              color: theme.palette.primary.main,
            },
          }),
        },
        {
          props: { variant: 'text' },
          style: ({ theme }: { theme: Theme }) => ({
            fontWeight: 400,
            fontSize: '14px !important',
            color: theme.palette.primary.main,
            border: 'none',
            '&:hover': {
              backgroundColor: 'transparent',
              color: alpha(theme.palette.accent.primary.main, 1),
            },
          }),
        },
      ],
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'projectsMainDashboardHeader' },
          style: ({ theme }: { theme: Theme }) => ({
            fontSize: 24,
            fontWeight: 400,
            lineHeight: '32px',
            color: theme.palette.accent.gray.main,
            fontFamily: 'Roboto, Urbanist, Arial, sans-serif',
          }),
        },
        {
          props: { variant: 'projectCardSubtitle' },
          style: ({ theme }: { theme: Theme }) => ({
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '143%',
            letterSpacing: '0.17px',
            color: theme.palette.accent.gray.dark,
            fontFamily: 'Urbanist, sans-serif, Arial',
            fontStyle: 'normal',
          }),
        },
        {
          props: { variant: 'projectCardTitle' },
          style: ({ theme }: { theme: Theme }) => ({
            fontSize: '20px',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '0.14px',
            color: theme.palette.accent.gray.dark,
            fontFamily: 'Urbanist, sans-serif, Arial',
            fontStyle: 'normal',
          }),
        },
        {
          props: { variant: 'toolmenu' },
          style: ({ theme }: { theme: Theme }) => ({
            fontSize: 15,
            fontWeight: 500,
            lineHeight: '26px',
            color: theme.palette.primary.main,
            fontFamily: 'Urbanist, Arial, sans-serif',
          }),
        },
        {
          props: { variant: 'popupTitle' },
          style: ({ theme }: { theme: Theme }) => ({
            fontSize: '25px !important',
            fontWeight: 600,
            lineHeight: '56px',
            color: theme.palette.primary.main,
            fontFamily: 'Urbanist, Arial, sans-serif',
          }),
        },
        {
          props: { variant: 'sidePanelLogs' },
          style: ({ theme }: { theme: Theme }) => ({
            fontSize: '.7rem',
            fontWeight: 400,
            lineHeight: '1.2',
            letterSpacing: '0.17px',
            color: theme.palette.primary.main,
            fontFamily: 'Urbanist, sans-serif, Arial',
            maxHeight: '15rem',
            textAlign: 'left',
            '&.sidePanelLogs-info': {
              color: 'blue',
            },
            '&.sidePanelLogs-error': {
              color: 'red',
            },
            '&.sidePanelLogs-warning': {
              color: 'orange',
            },
          }),
        },
        {
          props: { variant: 'sidePanelLogsMoreInfo' },
          style: ({ theme }: { theme: Theme }) => ({
            fontSize: '.6rem',
            fontWeight: 400,
            lineHeight: '1.2',
            letterSpacing: '0.17px',
            color: theme.palette.primary.main,
            fontFamily: 'Urbanist, sans-serif, Arial',
            maxHeight: '15rem',
            textAlign: 'left',
            '&.sidePanelLogsMoreInfo-key': {
              fontWeight: 600,
            },
          }),
        },
        {
          props: { variant: 'dynamicValue' },
          style: ({ theme }: { theme: Theme }) => ({
            fontSize: '12px',
            fontWeight: 400,
            fontFamily: 'Noto Sans Mono, monospace',
            color: alpha(theme.palette.primary.main, 0.7),
          }),
        },
      ],
      styleOverrides: {
        root: {
          fontSize: '16px',
          letterSpacing: '0.15px',
          fontFamily: 'Urbanist, Arial, sans-serif',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          width: '100%',
        },
        input: {
          fontSize: '16px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          '& .MuiInputLabel-root.MuiInputLabel-shrink': {
            color: theme.palette.accent.primary.main,
          },
        }),
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          background: 'none',
          boxShadow: 'none',
          borderBottom: `1px solid ${theme.palette.divider}`,
          '&:last-child': {
            borderBottom: 0,
          },
          '&:before': {
            display: 'none',
          },
        }),
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          flexDirection: 'row-reverse',
          '&.Mui-expanded': {
            minHeight: '0px',
          },
        },
        content: {
          '&.Mui-expanded': {},
        },
        expandIconWrapper: {
          paddingLeft: '10px',
          paddingRight: '10px',
          filter: 'grayscale(100%)',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          marginLeft: '7px',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          marginLeft: '7px',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {},
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          minWidth: '457px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          fontSize: '24px',
          fontWeight: 400,
          padding: '24px',
          margin: 0,
          background: theme.palette.primary.main,
          borderBottom: `1px solid ${theme.palette.primary.main}`,
          marginBottom: '16px',
        }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          lineHeight: '24px',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          '&.charging': {
            outline: `solid ${theme.palette.accent.secondary.main} 3px`,
          },
        }),
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          '&.project-card-menu-button': {
            '&:hover': {
              backgroundColor: alpha(theme.palette.background.default, 0.3),
            },
            position: 'absolute',
            top: 0,
            right: 0,
            zIndex: 3,
          },
          padding: 0,
        }),
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }: { theme: Theme }) => ({
          backgroundColor: theme.palette.secondary.main,
          borderRadius: '5px',
          color: alpha(theme.palette.primary.main, 0.77),
          margin: 0,
          padding: '5px 16px',
          boxShadow: `2px 3px 10px 2px ${alpha(theme.palette.primary.main, 0.54)}`,
        }),
        arrow: ({ theme }: { theme: Theme }) => ({
          color: theme.palette.secondary.main,
          '&::before': {
            border: `solid 1px ${alpha(theme.palette.primary.main, 0.24)}`,
          },
        }),
      },
    },
  },
};
