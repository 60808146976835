import { DeviceConnectionStatus } from '@electreon/electreon-device-metadata-service-gen-ts-client';
import { Depot, ManagementUnitWithParkingSpots } from '@electreon/electreon-metadata-service-gen-ts-client';
import { action, computed, makeAutoObservable, runInAction, toJS } from 'mobx';
import { getProjectParkingSpots, getProjectDeployment, getProjectVehicles } from 'Utils/APIUtils';
import {
  isVehicleFullyCharged,
  isVuOffline,
  ocppConnectorStatusToDepotState,
} from 'Screens/DepotDashboard/Utils/depotUtils';
import { LockInfo } from '@electreon_ui/shared/types/socketMessageTypes';
import { ChargingActivitiesSocketMessage } from '@electreon_ui/shared/types/socketMessageTypes';
import { VehicleUnitModelRefined } from '@electreon_ui/shared/types/globals';
import { AppMode, AppOperationalMode, CONNECTION_STATUS } from '@electreon_ui/shared/constants/constants';
import {
  DepotMapMetadata,
  ParkingSpotState,
  ParkingSpotWithState,
  VUDevicesData,
  VUWithState,
} from 'MobxStores/Types/DepotTypes';
import { isAppModeV2 } from '@electreon_ui/shared/utils/globalUtils';
import { type ParsedMeterValues } from '@electreon_ui/shared/utils/ocppUtils';
import { RootStore } from 'MobxStores/RootStore';
import { Coordinate } from 'ol/coordinate';

export namespace DepotTypes {
  export type MuId = string;
  export type OcppId = string;
  export type MuState = { isCharging?: boolean; connectionStatus?: DeviceConnectionStatus } | undefined;
  export type RoadSectionId = string;
  export type RoadSectionState = { isCharging?: boolean } | undefined;
  export type OcppState =
    | {
        isCharging?: boolean;
        connectionStatus?: DeviceConnectionStatus;
        connectors?: Record<number, ParkingSpotState & { meterValues: ParsedMeterValues }>;
      }
    | undefined;
}

export class DepotStore {
  rootStore: RootStore;
  lastAlive: Date | null = null;
  depot: Depot | null | undefined = null;
  depotId: string | number | null = null;
  projectId: string | null = null;
  isLoading = false;
  parkingSpots: Record<string, ParkingSpotWithState> = {};
  vuDevices: VUDevicesData = {};
  lockInfo: Record<string, LockInfo> = {};
  staticMuList: ManagementUnitWithParkingSpots[] = [];
  muAppModes: Record<string, 0 | 1 | 2 | 3> = {};
  muAppOperationalModes: Record<string, AppOperationalMode> = {};
  vuPopupOpen = false;
  parkingPopupOpen = false;
  selectedParkingSpot: ParkingSpotWithState | null = null;
  selectedVehicle: VUWithState | null = null;
  selectedAquariumVehicle: VUWithState | null = null;
  liveChargingData: Record<string, ChargingActivitiesSocketMessage> = {};
  musStates: Record<DepotTypes.MuId, DepotTypes.MuState> = {};
  roadSectionState: Record<DepotTypes.RoadSectionId, DepotTypes.RoadSectionState> = {};
  selectedMapCenter?: Coordinate | null = null;
  selectedMapZoom?: number | null = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  async init(projectId: string, depotId: string, isUpdate?: boolean) {
    this.depotId = depotId;
    this.projectId = projectId;
    try {
      !isUpdate && this.setIsLoading(true);
      await this.setVehicles();
      await this.setParkingSpots();
      this.setMusStates();
      this.setRoadSectionsStates();
      this.setInitialLockInfo(); // set initial lock info from initial parking spot states
      this.setLastAlive();
    } catch (error: any) {
      console.error('Error occured while initializing depot store', error);
    }
    runInAction(() => this.setIsLoading(false));
  }

  setLastAlive() {
    this.lastAlive = new Date();
  }

  setIsLoading(loading: boolean) {
    this.isLoading = loading ? true : false;
  }

  setMuAppMode(muId: string, appMode: 0 | 1 | 2 | 3) {
    this.muAppModes = {
      ...this.muAppModes,
      [muId]: appMode,
    };
  }

  setMuAppOperationalMode(muId: string, operationalMode: AppOperationalMode) {
    this.muAppOperationalModes = {
      ...this.muAppOperationalModes,
      [muId]: operationalMode,
    };
  }

  isParkingSpotMuInManualMode(parkingSpot?: ParkingSpotWithState) {
    if (!parkingSpot) return false;
    const isV2 = isAppModeV2(parkingSpot.muData?.softwareVersion);
    let isManualMode = false;
    if (isV2) {
      isManualMode = this.muAppOperationalModes[parkingSpot.muId || ''] === AppOperationalMode.Manual;
    } else {
      isManualMode = this.muAppModes[parkingSpot.muId || ''] === AppMode.Static_Deprecated_Manual;
    }
    return isManualMode;
  }

  private setInitialLockInfo() {
    for (const { managementUnit } of this.staticMuList) {
      if (!managementUnit?.id || !managementUnit.lockInfo) continue;
      this.lockInfo[managementUnit.id] = managementUnit.lockInfo as LockInfo;
    }
  }

  async setVehicles(vehicleArray?: { data: { devices: VehicleUnitModelRefined[] } }) {
    if (!this.projectId) return;
    try {
      const vuDevices = vehicleArray || (await getProjectVehicles(this.projectId));
      if (!vuDevices.data.devices) {
        console.error('No vehicles found');
        return;
      }
      const vuDevicesMap = vuDevices.data.devices.reduce<VUDevicesData>((acc, vuDevice) => {
        if (!vuDevice.id) {
          console.error('Vehicle unit id not found');
          return acc;
        }
        const lastKnownBatteryLevel = vuDevice.lastKnownState?.battery_precentage;
        const { lat, lon } = vuDevice.lastKnownState?.location || {};
        const lastKnownPosition = lat && lon ? { lat, lng: lon } : null;
        acc[vuDevice.id] = vuDevice;
        acc[vuDevice.id].state = acc[vuDevice.id].state || {
          charging: Boolean(vuDevice.chargingState),
          inParkingSpot: false,
          parkingSpotId: null,
          batteryLevel: lastKnownBatteryLevel || null,
          wptError: false,
          power: null,
          isTransitioning: false,
          position: lastKnownPosition || { lat: 0, lng: 0 },
          timeToFullCharge: { hours: 0, minutes: 0 },
          shouldRepark: Boolean(vuDevice.shouldRepark),
          outOfService: Boolean(vuDevice.outOfService),
        };
        return acc;
      }, {});
      runInAction(() => (this.vuDevices = vuDevicesMap));
    } catch (error: any) {
      console.error('Error occured while fetching vehicles', error);
    }
  }

  async setParkingSpots(mockSpots?: { data: ParkingSpotWithState[] }) {
    if (!this.depotId || !this.projectId) {
      !this.depotId && console.error('Depot id not found');
      !this.projectId && console.error('Project id not found');
      return;
    }
    try {
      const parkingSpots: { data: ParkingSpotWithState[] } =
        mockSpots || (await getProjectParkingSpots(this.depotId));
      const deploymentData = mockSpots ? {} : (await getProjectDeployment(this.projectId)).data;
      this.rootStore.projectStore.setProjectDeployment(this.projectId, deploymentData);
      const parkingSpotsMap = parkingSpots.data.reduce<Record<string, ParkingSpotWithState>>(
        (acc, parkingSpot) => {
          if (!parkingSpot.id || !parkingSpot.muId) {
            !parkingSpot.id && console.error('Parking spot id not found');
            !parkingSpot.muId && console.error('Management unit id not found');
            return acc;
          }
          acc[parkingSpot.id] = { ...parkingSpot, state: parkingSpot?.state || {} };
          acc[parkingSpot.id].state = acc[parkingSpot.id].state || {
            vuPaired: false,
            pairedVuData: null,
            parkingSpotId: null,
            charging: false,
            occupied: false,
            nonOperational: false,
            hovered: false,
            paused: false,
            vacant: false,
          };
          acc[parkingSpot.id].muData = this.rootStore.projectStore.muMap.get(parkingSpot.muId);

          if (!acc[parkingSpot.id].muData) {
            // remove parking spot if mu assigned to it is not found
            console.warn(
              `Management unit ${parkingSpot.muId} is assigned to parking spot ${parkingSpot.id} but does not exist in project ${this.projectId} deployment data`
            );
            // delete acc[parkingSpot.id];
          }
          return acc;
        },
        {}
      );
      return runInAction(() => {
        this.parkingSpots = parkingSpotsMap;
        this.depot = deploymentData.staticDeployment?.depots?.find(
          (depot) => depot.id === Number(this.depotId)
        );
        this.staticMuList =
          deploymentData.staticDeployment?.depots
            ?.find((depot) => depot.id === Number(this.depotId))
            ?.managementUnits?.sort(
              (a, b) =>
                (a.managementUnit?.name &&
                  b.managementUnit?.name &&
                  a?.managementUnit?.name?.localeCompare(b.managementUnit?.name)) ||
                0
            ) || [];
        return deploymentData;
      });
    } catch (error: any) {
      console.error('Error occured while fetching parking spots', error);
    }
  }

  setMusStates() {
    for (const mu of this.rootStore.projectStore.muList) {
      if (!mu.id) {
        console.error('Management unit id not found');
        continue;
      }
      this.musStates = {
        ...this.musStates,
        [mu.id]: {
          isCharging: Boolean(mu.chargingState),
          connectionStatus: mu.connectionStatus,
        },
      };
    }
  }

  @computed
  get ocppParkingSpotsMapData() {
    const mapMetadata = this.rootStore.queryClient.getQueryData<DepotMapMetadata>([
      'depotMapConfig',
      this.projectId,
      this.depotId,
    ]);
    return mapMetadata?.parkingSpots?.filter((spot) => spot.type === 'ocppSpot') || [];
  }

  setRoadSectionsStates() {
    Object.entries(Object.fromEntries(this.rootStore.projectStore.roadSectionMap)).forEach(
      ([roadSectionId, roadSection]) => {
        const muIdList = roadSection.managementUnits?.map((mu) => mu.id ?? '') || [];

        this.roadSectionState[roadSectionId] = {
          ...(this.roadSectionState[roadSectionId] || {}),
          isCharging: muIdList.some((muId) => this.musStates[muId]?.isCharging),
        };
      }
    );
  }

  setVehicleCharging(vuDeviceId: string, charging: boolean) {
    const device = this.vuDevices[vuDeviceId];
    if (!device?.state) return;
    this.vuDevices = {
      ...this.vuDevices,
      [vuDeviceId]: {
        ...device,
        state: { ...device.state, charging },
      },
    };
  }

  setVehicleError(vuDeviceId: string, wptError: boolean) {
    if (!this.vuDevices[vuDeviceId]?.state) return;
    this.vuDevices = {
      ...this.vuDevices,
      [vuDeviceId]: {
        ...this.vuDevices[vuDeviceId],
        state: {
          ...this.vuDevices[vuDeviceId].state,
          wptError,
        },
      },
    };
  }

  setParkingSpotCharging(parkingSpotId: string, charging: boolean) {
    const parkingSpot = this.parkingSpots[parkingSpotId];
    if (!parkingSpot) return;
    this.parkingSpots = {
      ...this.parkingSpots,
      [parkingSpotId]: {
        ...parkingSpot,
        state: { ...parkingSpot.state, charging },
      },
    };
  }

  setParkingSpotOccupied(parkingSpotId: string, occupied: boolean) {
    if (!this.parkingSpots[parkingSpotId]?.state) return;
    this.parkingSpots = {
      ...this.parkingSpots,
      [parkingSpotId]: {
        ...this.parkingSpots[parkingSpotId],
        state: { ...this.parkingSpots[parkingSpotId].state, occupied },
      },
    };
  }

  setParkingSpotVacant(parkingSpotId: string, vacant: boolean) {
    if (!this.parkingSpots[parkingSpotId]?.state) return;
    this.parkingSpots = {
      ...this.parkingSpots,
      [parkingSpotId]: {
        ...this.parkingSpots[parkingSpotId],
        state: { ...this.parkingSpots[parkingSpotId].state, vacant },
      },
    };
  }

  setParkingSpotNonOperational(parkingSpotId: string, nonOperational: boolean) {
    if (!this.parkingSpots[parkingSpotId]?.state) return;
    this.parkingSpots = {
      ...this.parkingSpots,
      [parkingSpotId]: {
        ...this.parkingSpots[parkingSpotId],
        state: { ...this.parkingSpots[parkingSpotId].state, nonOperational },
      },
    };
  }

  setParkingSpotPaused(parkingSpotId: string, paused: boolean) {
    if (!this.parkingSpots[parkingSpotId]?.state) return;
    this.parkingSpots = {
      ...this.parkingSpots,
      [parkingSpotId]: {
        ...this.parkingSpots[parkingSpotId],
        state: { ...this.parkingSpots[parkingSpotId].state, paused },
      },
    };
  }

  setVehicleTimeToFullCharge(vuDeviceId: string, hours: number | null, minutes: number | null) {
    if (!this.vuDevices[vuDeviceId]?.state) return;
    this.vuDevices = {
      ...this.vuDevices,
      [vuDeviceId]: {
        ...this.vuDevices[vuDeviceId],
        state: {
          ...this.vuDevices[vuDeviceId].state,
          timeToFullCharge: { hours, minutes },
        },
      },
    };
  }

  setVehicleBatteryLevel(vuDeviceId: string, batteryLevel: number) {
    if (!this.vuDevices[vuDeviceId]?.state || !batteryLevel) return;
    this.vuDevices = {
      ...this.vuDevices,
      [vuDeviceId]: {
        ...this.vuDevices[vuDeviceId],
        state: {
          ...this.vuDevices[vuDeviceId].state,
          batteryLevel,
        },
      },
    };
  }

  setVehiclePowerState(vuDeviceId: string, power: number) {
    if (!this.vuDevices[vuDeviceId]?.state) return;
    this.vuDevices = {
      ...this.vuDevices,
      [vuDeviceId]: {
        ...this.vuDevices[vuDeviceId],
        state: {
          ...this.vuDevices[vuDeviceId].state,
          power,
        },
      },
    };
  }

  setVehicleshouldRepark(vuDeviceId: string, shouldRepark: boolean) {
    if (!this.vuDevices[vuDeviceId]?.state) return;
    // this.vuDevices[vuDeviceId].state!.shouldRepark = shouldRepark;
    this.vuDevices = {
      ...this.vuDevices,
      [vuDeviceId]: {
        ...this.vuDevices[vuDeviceId],
        state: {
          ...this.vuDevices[vuDeviceId].state,
          shouldRepark,
        },
      },
    };
  }

  setVehicleOutOfService(vuDeviceId: string, outOfService: boolean) {
    if (!this.vuDevices[vuDeviceId]?.state) return;
    // this.vuDevices[vuDeviceId].state!.outOfService = outOfService;
    this.vuDevices = {
      ...this.vuDevices,
      [vuDeviceId]: {
        ...this.vuDevices[vuDeviceId],
        state: {
          ...this.vuDevices[vuDeviceId].state,
          outOfService,
        },
      },
    };
  }

  @action
  setVehiclePosition(vuDeviceId: string, position: { lat: number; lng: number }) {
    const device = this.vuDevices[vuDeviceId];
    if (!device?.state) return;
    this.vuDevices = {
      ...this.vuDevices,
      [vuDeviceId]: {
        ...device,
        state: { ...device.state, position },
      },
    };
  }

  setVehicleConnectionState(vuDeviceId: string, connected: boolean) {
    if (!this.vuDevices[vuDeviceId].state) return;
    this.vuDevices[vuDeviceId].connectionStatus = connected
      ? DeviceConnectionStatus.Connected
      : DeviceConnectionStatus.Disconnected;

    if (!connected) {
      this.vuDevices = {
        ...this.vuDevices,
        [vuDeviceId]: {
          ...this.vuDevices[vuDeviceId],
          state: {
            ...this.vuDevices[vuDeviceId].state,
            charging: false,
            power: null,
            wptError: false,
          },
        },
      };
    }
  }

  setMuConnectionState(muId: string, connected: boolean) {
    this.musStates = {
      ...this.musStates,
      [muId]: {
        ...this.musStates[muId],
        connectionStatus: connected ? DeviceConnectionStatus.Connected : DeviceConnectionStatus.Disconnected,
      },
    };
  }

  setMuCharging(muId: string, isCharging: boolean) {
    this.musStates = {
      ...this.musStates,
      [muId]: {
        ...this.musStates[muId],
        isCharging,
      },
    };

    // update road section state
    this.roadSectionState = Object.entries(this.roadSectionState).reduce<
      Record<string, DepotTypes.RoadSectionState>
    >(
      (newRoadSectionStates, [roadSectionId, roadSectionState]) => {
        const muIdList =
          this.rootStore.projectStore.roadSectionToManagementUnitIdsMap.get(roadSectionId) || [];
        newRoadSectionStates[roadSectionId] = {
          ...roadSectionState,
          isCharging: muIdList.some((muId) => this.musStates[muId]?.isCharging),
        };
        return newRoadSectionStates;
      },
      { ...this.roadSectionState }
    );
  }

  setParkingSpotHoverState(parkingSpotId: string, isHovered: boolean) {
    if (!this.parkingSpots[parkingSpotId]?.state) {
      console.error(`Received socket data for unknown parking spot ${parkingSpotId}`);
      return;
    }
    this.parkingSpots = {
      ...this.parkingSpots,
      [parkingSpotId]: {
        ...this.parkingSpots[parkingSpotId],
        state: { ...this.parkingSpots[parkingSpotId].state, hovered: isHovered },
      },
    };
  }

  setLockState = (params: { type: string; payload: { lockInfo: Record<string, LockInfo> } }) => {
    const { type, payload } = params;
    if (type === 'SET_LOCK_MESSAGE') {
      this.lockInfo = payload.lockInfo;
    }
  };

  setVuPopupOpen = (open: boolean) => {
    this.vuPopupOpen = open;
  };

  setParkingPopupOpen = (open: boolean) => {
    this.parkingPopupOpen = open;
  };

  setSelectedParking = (parkingSpot: ParkingSpotWithState | null) => {
    this.selectedParkingSpot = parkingSpot;
  };

  setSelectedVehicle = (vehicle: VUWithState | null) => {
    this.selectedVehicle = vehicle;
  };

  setSelectedAquariumVehicle = (vehicle: VUWithState | null) => {
    this.selectedAquariumVehicle = vehicle;
  };

  setLiveChargingData = (chargingActivityMessage: ChargingActivitiesSocketMessage) => {
    if (!chargingActivityMessage.deviceId) console.error('Device id not found in charging activity message');
    this.liveChargingData = {
      ...this.liveChargingData,
      [chargingActivityMessage.deviceId]: chargingActivityMessage,
    };
  };

  setMapZoomAndCenter = ({
    mapCenter,
    mapZoom,
  }: {
    mapCenter?: Coordinate | null;
    mapZoom?: number | null;
  }) => {
    this.selectedMapCenter = mapCenter;
    this.selectedMapZoom = mapZoom;
  };

  getParkingSpotStateText(parkingSpot: { id: string }, isManualMode: boolean) {
    const state = this.parkingSpots[parkingSpot.id]?.state;
    const muId = this.parkingSpots[parkingSpot.id]?.muId;
    if (!state) return 'Unknown';
    const isOffline =
      this.parkingSpots[parkingSpot.id]?.muData?.connectionStatus === CONNECTION_STATUS.DISCONNECTED;
    const states = [];
    if (isOffline) states.push('Offline');
    if (state.vacant) states.push('Vacant');
    if (state.occupied) states.push('Occupied');
    if (state.charging) states.push('Charging');
    if (state.paused) states.push('Paused');
    if (state.nonOperational && !isManualMode) states.push('Error');
    if (isManualMode) states.push('Manual Mode');
    return new Intl.ListFormat('en', {
      style: 'long',
      type: 'conjunction',
    }).format(states);
  }

  getVehicleStateText(vehicle: { id: string }) {
    const state = this.vuDevices[vehicle.id]?.state;
    if (!state) return 'Unknown';
    const isOffline = isVuOffline(this.vuDevices[vehicle.id]);
    const states = [];
    if (state.charging) states.push('Charging');
    if (state.wptError) states.push('WPT Error');
    if (state.inParkingSpot) states.push('In Parking Spot');
    if (isOffline) states.push('Offline');
    return new Intl.ListFormat('en', {
      style: 'long',
      type: 'conjunction',
    }).format(states);
  }

  @computed
  get vacantParkingSpots() {
    return Object.values(this.parkingSpots).filter((parkingSpot) => parkingSpot.state?.vacant);
  }

  @computed
  get chargingParkingSpots() {
    return Object.values(this.parkingSpots).filter((parkingSpot) => parkingSpot.state?.charging);
  }

  @computed
  get offlineParkingSpots() {
    return Object.values(this.parkingSpots).filter((parkingSpot) => {
      return parkingSpot?.muData?.connectionStatus === CONNECTION_STATUS.DISCONNECTED;
    });
  }

  @computed
  get errorParkingSpots() {
    return Object.values(this.parkingSpots).filter((parkingSpot) => {
      // mu in manual mode, ignore error state
      if (this.isParkingSpotMuInManualMode(parkingSpot)) return false;
      return (
        parkingSpot.state?.nonOperational ||
        (!Object.keys(parkingSpot.state || {}).length &&
          parkingSpot?.muData?.connectionStatus === CONNECTION_STATUS.CONNECTED)
      );
    });
  }

  @computed
  get pausedParkingSpots() {
    return Object.values(this.parkingSpots).filter((parkingSpot) => parkingSpot?.state?.paused);
  }

  @computed
  get allParkingSpots() {
    return Object.values(this.parkingSpots);
  }

  @computed
  get occupiedParkingSpots() {
    return Object.values(this.parkingSpots).filter((parkingSpot) => parkingSpot.state?.occupied);
  }

  @computed
  get parkingSpotsWithMuInManualMode() {
    return Object.values(this.parkingSpots).filter((parkingSpot) => {
      return (
        parkingSpot.muData?.connectionStatus === CONNECTION_STATUS.CONNECTED &&
        this.isParkingSpotMuInManualMode(parkingSpot)
      );
    });
  }

  @computed
  get connectedVehicles() {
    return Object.values(this.vuDevices).filter(
      (vuDevice) => vuDevice.connectionStatus === CONNECTION_STATUS.CONNECTED
    );
  }

  @computed
  get allVehicles() {
    return Object.values(this.vuDevices);
  }

  @computed
  /** Returns all vehicles that are not out of service */
  get allOperationalVehicles() {
    return Object.values(this.vuDevices).filter((vuDevice) => !vuDevice.state?.outOfService);
  }

  @computed
  get nonChargingVehicles() {
    return Object.values(this.vuDevices).filter(
      (vuDevice) =>
        !vuDevice.state?.charging &&
        vuDevice.connectionStatus === CONNECTION_STATUS.CONNECTED &&
        !vuDevice.state?.wptError &&
        !isVehicleFullyCharged(vuDevice) &&
        !vuDevice.state?.shouldRepark &&
        !vuDevice.state?.outOfService
    );
  }

  @computed
  get chargingVehicles() {
    return Object.values(this.vuDevices).filter(
      (vuDevice) =>
        vuDevice.state?.charging &&
        !vuDevice.state?.outOfService &&
        vuDevice.connectionStatus === CONNECTION_STATUS.CONNECTED
    );
  }

  @computed
  get fullyChargedVehicles() {
    return Object.values(this.vuDevices).filter(
      (vehicle) => isVehicleFullyCharged(vehicle) && !vehicle.state?.outOfService
    );
  }

  @computed
  get vehiclesWithWPTError() {
    return Object.values(this.vuDevices).filter(
      (vuDevice) => vuDevice.state?.wptError && !vuDevice.state?.outOfService
    );
  }

  @computed
  get pairedVehicles() {
    return Object.values(this.vuDevices).filter((vuDevice) => vuDevice.state?.inParkingSpot);
  }

  @computed
  get offlineVehicles() {
    return Object.values(this.vuDevices).filter(
      (vuDevice) => isVuOffline(vuDevice) && !vuDevice.state?.outOfService
    );
  }

  @computed
  get shouldReparkVehicles() {
    return Object.values(this.vuDevices).filter(
      (vuDevice) => vuDevice.state?.shouldRepark && !vuDevice.state?.outOfService
    );
  }

  @computed
  get outOfServiceVehicles() {
    return Object.values(this.vuDevices).filter((vuDevice) => vuDevice.state?.outOfService);
  }

  @computed
  get ocppParkingSpotStateCounts() {
    const counts: {
      vacant: number;
      occupied: number;
      charging: number;
      nonOperational: number;
      offline: number;
      paused: number;
    } = {
      vacant: 0,
      occupied: 0,
      charging: 0,
      nonOperational: 0,
      offline: 0,
      paused: 0,
    };

    for (const charger of this.rootStore.projectStore.ocppList) {
      if (!charger.id) continue;
      const connectorCount = this.rootStore.ocppStore.ocppIdToConnectorCountMap.get(charger.id);
      for (let i = 1; i <= (connectorCount ?? 0); i++) {
        const connectorStatus = this.rootStore.ocppStore.getConnectorStatus(charger.id, i);
        if (!connectorStatus) {
          console.error('No status notification found for connector', i, 'of charger', charger.id);
          continue;
        }

        const parkingSpotState = ocppConnectorStatusToDepotState.get(connectorStatus.status);
        const isChargerConnected = this.rootStore.ocppStore.connectionState[charger.id];

        if (!isChargerConnected) counts.offline++;
        else if (parkingSpotState?.charging) counts.charging++;
        else if (parkingSpotState?.vacant) counts.vacant++;
        else if (parkingSpotState?.occupied) counts.occupied++;
        else if (parkingSpotState?.nonOperational) counts.nonOperational++;
        else if (parkingSpotState?.paused) counts.paused++;
      }
    }

    return counts;
  }

  resetStore() {
    this.depotId = null;
    this.isLoading = false;
    this.parkingSpots = {};
    this.vuDevices = {};
    this.projectId = null;
    this.selectedMapCenter = null;
    this.selectedMapZoom = null;
  }
}
