import { Box, Button, Divider, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { DeleteUserInput, DeleteUserSchema } from './ManageUsersFormsUtils';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormSubmitionButtons } from 'Components/Buttons/FormSubmitAndCancelButtons/FormSubmitionButtons';
import { useAppStore } from 'MobxStores/context';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { toast } from 'sonner';

export const DeleteUserForm: React.FC<FormProps> = observer(({ onSuccessfulSubmit, onCancel }) => {
  const [loading, setLoading] = useState(false);
  const { authStore } = useAppStore();
  const methods = useForm<DeleteUserInput>({
    resolver: zodResolver(DeleteUserSchema),
    defaultValues: {
      username: '',
    },
  });
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = methods;

  const onSubmitHandler: SubmitHandler<DeleteUserInput> = (values) => {
    setLoading(true);
    const { username } = values;
    authStore
      .deleteUserAccount(username)
      .then(() => {
        toast.success(`User Account Deleted Successfully`);
      })
      .catch((error) => {
        console.error(error, 'error');
        toast.error(`The user account was not deleted, please try again.`);
      });
    onSuccessfulSubmit();
  };

  const getUserHandler = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    const email = methods.getValues('email');
    authStore
      .getUserData(email)
      .then((userData) => {
        if (!userData || Array.isArray(userData)) {
          !userData && toast.error('There are no users found with this email.');
          Array.isArray(userData) &&
            toast.error('There are more than one user with this email. please contact the software team.');
          methods.reset({ username: '' });
          return;
        }
        methods.reset({
          username: userData.Username,
        });
      })
      .catch((error) => {
        toast.error('Something went wrong with getting the user Data, please try again later.');
        methods.reset({ username: '' });
      });
  };

  return (
    <FormProvider {...methods}>
      <Typography variant='h4' component='h1'>
        Delete User
      </Typography>
      <Box
        component='form'
        noValidate
        autoComplete='off'
        sx={{ mt: 4 }}
        onSubmit={handleSubmit(onSubmitHandler, (errors) => {
          console.log(errors);
        })}
      >
        <Stack direction='column' spacing={2} sx={{ mb: 2 }}>
          <TextField
            label='User Email'
            fullWidth
            required
            error={!!errors['email']}
            helperText={errors['email'] ? errors['email'].message : ''}
            {...register('email')}
            onChange={() => {
              methods.reset({ username: '' });
            }}
          />
          <Button variant='contained' onClick={getUserHandler}>
            Find User
          </Button>
        </Stack>
        <Divider sx={{ mt: 1, mb: 2 }} />
        <FormSubmitionButtons
          loading={loading}
          onCancel={onCancel}
          submitLabel='Delete'
          confirmation
          confirmTitle={`Are You Sure You Want To Delete User Account?`}
          confirmMessage={`User will not have access to Smart Road platform, and all his/her data will be lost!`}
          onSubmitHandler={onSubmitHandler}
          disableSubmit={!methods.getValues('username')}
          {...methods.watch()}
        />
      </Box>
    </FormProvider>
  );
});
