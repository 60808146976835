import {
  DataGraphParameterType,
  DataGraphRequest,
  IoTDeviceType,
} from '@electreon/electreon-device-telemetry-service-gen-ts-client';
import { computed, makeAutoObservable, runInAction, action } from 'mobx';
import {
  AdvancedParamsData,
  DataToExport,
  SelectedDevices,
  SummaryTableData,
  TimeSeries,
  GetSummaryTableData,
  GetDataForSelectedTimespan,
  SetSelectedTimespan,
  TimeRulerBorder,
} from 'MobxStores/Types';
import {
  AnalyticsEventSummary,
  SelectedEventCategoriesAndTypes as SelectedEventCategoriesAndTypes,
  EventCategory,
  isEventCategory,
  FormattedTimeSeriesTelemetryModelDeviceEventLog,
  StickyRefLineItem,
  SharedUrlData,
  DutyCycleItem,
  ExpandedParams,
} from 'MobxStores/Types/AnalyticsTypes';
import { isDataRequest, isValidSharedParams } from 'MobxStores/utils/typeCheckers';
import { createTimeSeries, getFormattedTableData, getStartAndEndTime } from 'Utils/AnalyticUtils';
import {
  fetchAdvancedParamsData,
  getAnalyticsDeviceActivity,
  getEventsTimeSeries,
  getTableData,
} from 'Utils/APIUtils';
import { v4 as uuidv4 } from 'uuid';
import { api } from 'Services/api';
import {
  convertFormattedTimeToMiliseconds,
  DEVAULT_CATEGORY_SELECTION,
  getSelectedEventsFromSummary,
  normalizeTimeSeriesLengths,
  prepareDataGraphParams,
  transformAnalyticsParams,
} from 'Utils/AnalyticsUtils';
import { UiDevice } from '@electreon_ui/shared/types/globals';
import { ParserEnumsMap } from '@electreon/electreon_messages/dist';
import { RootStore } from './RootStore';
import { addDays, subDays, set } from 'date-fns';
import { getDateTimeFormatWithMilliseconds } from 'Utils/utils';
import { toast } from 'sonner';
import { utcToZonedTime } from 'date-fns-tz';
import { isOcpp } from '@electreon_ui/shared/utils/globalUtils';

export class ProjectAnalyticsStore {
  rootStore: RootStore;
  selectedDevices: SelectedDevices = {}; // { deviceId: { device, selectedParameters: Set()}}
  projectParams: ExpandedParams = {};
  projectAnalyticsSummaryTableData: SummaryTableData = {};
  dataToExport: DataToExport = [];
  isLoadingDataFromServer: boolean = false;
  isLoadingGraphData: boolean = false;
  advancedParamsData: AdvancedParamsData = [];
  selectedDate: null | Date = null;
  deviceActivityData: TimeSeries = [];
  selectedActivityDevice?: string = '';
  selectedTimespanIndices = { startIndex: 0, endIndex: 0 };
  selectedTimespanValues: { startTime: number | Date; endTime: number | Date } = {
    startTime: new Date(),
    endTime: new Date(),
  };
  selectedTimeRange: TimeSeries = [];
  deviceActivityLoaded = false;
  defaultStartHour: number = 9;
  defaultEndHour: number = 9;
  defaultStartMinute: number = 0;
  defaultEndMinute: number = 40;
  showTimeRulerBorder: TimeRulerBorder = {
    left: true,
    right: true,
  };
  isZooming: boolean = false;
  timeValuesBeforeZoom: {
    start: Date | number;
    end: Date | number;
  } = { start: new Date(), end: new Date() };
  dataRequestHistory: Array<Parameters<GetDataForSelectedTimespan>> = [];
  historyTraversal: { stepsBack: number } = { stepsBack: 0 };
  isExportingRawData: boolean = false;
  isRawDataExportError: boolean = false;
  isRawDataExportSuccess: boolean = false;
  summaryTableRequestId: string | null = null;
  graphDataRequestId: string | null = null;
  activityTime: { start?: number; end?: number } = { start: undefined, end: undefined };
  eventsSummary: AnalyticsEventSummary = {};
  selectedEventSeveritiesAndTypes: SelectedEventCategoriesAndTypes = {};
  eventsTimeSeriesData: { [deviceId: string]: FormattedTimeSeriesTelemetryModelDeviceEventLog };
  hoveredTimestamp: number | undefined = undefined;
  /** usage: paramToEnumMap.get('paramName')?.[enumValue] -> 'textual representation' */
  paramToEnumMap = new ParserEnumsMap().getMap();
  stickyRefLinesObj: SharedUrlData['stickyRefLinesObj'] = {};
  dutyCycleObj: SharedUrlData['dutyCycleObj'] = {};

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.selectedDate = set(
      utcToZonedTime(new Date(), rootStore.projectStore?.selectedProject?.timezoneStr || 'Asia/Jerusalem'),
      { milliseconds: 0 }
    );
    this.selectedTimespanValues = {
      startTime: utcToZonedTime(
        new Date(),
        rootStore.projectStore?.selectedProject?.timezoneStr || 'Asia/Jerusalem'
      ).valueOf(),
      endTime: utcToZonedTime(
        new Date(),
        rootStore.projectStore?.selectedProject?.timezoneStr || 'Asia/Jerusalem'
      ).valueOf(),
    };
    makeAutoObservable(this, {
      projectParams: true,
      deviceForActivityGraph: computed,
      selectedParamCount: computed,
      selectedMuIds: computed,
      selectedVuIds: computed,
      rawDataExportRequestParams: computed,
      timezoneStr: computed,
      // non-observable properties
      paramToEnumMap: false,
      setTimeValuesBeforeZoom: action,
    });
  }

  get timezoneStr() {
    const timezoneStr = this.rootStore.projectStore?.selectedProject?.timezoneStr;
    return timezoneStr || 'Asia/Jerusalem';
  }

  setDeviceActivityLoaded = (value: boolean) => (this.deviceActivityLoaded = value);

  get rawDataExportRequestParams(): Array<DataGraphRequest> {
    return this.advancedParamsData.map((cell) => {
      if (!cell.deviceId || !cell.param) {
        console.error('cell.deviceId or cell.param is undefined', JSON.stringify(cell));
        return {};
      }
      return {
        deviceId: cell.deviceId,
        param: cell.param.id,
        filters: cell.filters,
      };
    });
  }

  stepBackInHistory = () => {
    const preventStepBack =
      this.historyTraversal.stepsBack >= this.dataRequestHistory.length - 1 ||
      this.dataRequestHistory.length < 2;
    if (preventStepBack) return;
    this.historyTraversal.stepsBack++;
    const previousRequest =
      this.dataRequestHistory[this.dataRequestHistory.length - this.historyTraversal.stepsBack - 1];
    if (isDataRequest(previousRequest)) {
      const [zoomStart, zoomEnd] = previousRequest;
      this.setSelectedTimespan(zoomStart, zoomEnd);
      this.getDataForSelectedTimespan(...previousRequest);
      this.getSummaryTableData(this.timezoneStr);
    }
  };

  stepForwardInHistory = () => {
    const preventStepForward = this.historyTraversal.stepsBack === 0;
    if (preventStepForward) return;
    this.historyTraversal.stepsBack--;
    const nextRequest =
      this.dataRequestHistory[this.dataRequestHistory.length - this.historyTraversal.stepsBack - 1];
    if (isDataRequest(nextRequest)) {
      const [zoomStart, zoomEnd] = nextRequest;
      this.setSelectedTimespan(zoomStart, zoomEnd);
      this.getDataForSelectedTimespan(...nextRequest);
      this.getSummaryTableData(this.timezoneStr);
    }
  };

  get selectedMuIds() {
    return Object.values(this.selectedDevices)
      .reduce<string[]>((acc, { device }) => {
        if (device.id && device.deviceType === 'MU') {
          acc.push(device.id);
        }
        return acc;
      }, [])
      .sort((a, b) => +a.slice(2) - +b.slice(2));
  }

  get selectedVuIds() {
    return Object.values(this.selectedDevices)
      .reduce<string[]>((acc, { device }) => {
        if (device.id && device.deviceType === 'VU') {
          acc.push(device.id);
        }
        return acc;
      }, [])
      .sort((a, b) => +a.slice(2) - +b.slice(2));
  }

  addDeviceToSelection(device: UiDevice, preventDefaultSelection?: boolean) {
    if (!device.id) return;
    if (this.selectedDevices[device.id]) return;
    const hasOnlyOneDcSampler = device.deviceSubType !== 'LSMU';
    const isMu = device.deviceType === 'MU';
    let defaultSelectedparameter;
    if (isMu) {
      defaultSelectedparameter = hasOnlyOneDcSampler
        ? { parameter: DataGraphParameterType.MuDcMeterTotalPowerAvg, filters: { dc_sampler_id: 0 } }
        : { parameter: DataGraphParameterType.MuDcMeterTotalPowerAvg };
    } else if (isOcpp(device)) {
      defaultSelectedparameter = { parameter: DataGraphParameterType.OcppDeviceTotalPower };
    } else {
      defaultSelectedparameter = { parameter: DataGraphParameterType.VuDcMeterPowerAvg };
    }
    this.selectedDevices[device.id] = {
      device,
      selectedParameters: preventDefaultSelection
        ? new Set()
        : new Set([JSON.stringify(defaultSelectedparameter)]),
    };
  }

  removeDeviceFromSelection(deviceId: string) {
    if (!this.selectedDevices[deviceId]) return;
    delete this.selectedDevices[deviceId];
  }

  toggleDeviceSelection = (device: UiDevice) => {
    if (!device.id) return;
    if (this.selectedDevices[device.id]) {
      this.removeDeviceFromSelection(device.id);
    } else {
      this.addDeviceToSelection(device);
    }
  };

  addParameterToSelection(device: UiDevice, parameter: DataGraphParameterType, filters: any) {
    if (!device.id) return;
    if (!this.selectedDevices[device.id]) {
      this.addDeviceToSelection(device);
    }
    this.selectedDevices[device.id].selectedParameters.add(JSON.stringify({ parameter, filters }));
  }

  removeParameterFromSelection = (deviceId?: string, parameter?: DataGraphParameterType, filters?: any) => {
    if (!deviceId || !parameter) return;
    if (!this.selectedDevices[deviceId]) return;
    this.selectedDevices[deviceId].selectedParameters.delete(
      JSON.stringify(filters ? { parameter, filters } : { parameter })
    );
    if (this.selectedDevices[deviceId].selectedParameters.size === 0) {
      this.removeDeviceFromSelection(deviceId);
    }
  };

  toggleParameterSelection(device: UiDevice, parameter: DataGraphParameterType, filters?: any) {
    if (!device.id) return;
    const isSelected = this.isParameterSelected(device, parameter, filters);
    const preventDefaultSelection = true;
    if (!isSelected && !this.selectedDevices[device.id]) {
      this.addDeviceToSelection(device, preventDefaultSelection);
    }

    if (isSelected) {
      this.removeParameterFromSelection(device.id, parameter, filters);
    } else {
      this.addParameterToSelection(device, parameter, filters);
    }
  }

  isDeviceSelected(deviceId: string) {
    return Boolean(this.selectedDevices[deviceId]);
  }

  isParameterSelected(device: UiDevice, parameter: DataGraphParameterType, filters?: any) {
    if (!device.id) return false;
    if (!this.selectedDevices[device.id]) return false;
    const paramToCheck = { parameter, filters };
    return this.selectedDevices[device.id].selectedParameters.has(JSON.stringify(paramToCheck));
  }

  setProjectParams(analyticsParamsResponse: ExpandedParams) {
    this.projectParams = analyticsParamsResponse;
  }

  setProjectAnalyticsSummaryTableData(tableData: SummaryTableData) {
    this.projectAnalyticsSummaryTableData = tableData;
  }

  setIsLoadingDataFromServer = (isLoading: boolean) => (this.isLoadingDataFromServer = isLoading);

  setIsLoadingGraphData = (isLoading: boolean) => (this.isLoadingGraphData = isLoading);

  setDataToExport(dataToExport: DataToExport) {
    this.dataToExport = dataToExport;
  }

  setAdvancedParamsData = (data: AdvancedParamsData) => {
    this.advancedParamsData = data;
  };

  setEventsSummary = (eventsSummary: AnalyticsEventSummary) => {
    this.eventsSummary = eventsSummary;
  };

  setSelectedEventTypeForDevice = ({
    deviceId,
    category,
    eventType,
    active,
  }: {
    deviceId: string;
    category: EventCategory;
    eventType: string;
    active: boolean;
  }) => {
    if (!this.selectedEventSeveritiesAndTypes[deviceId]) {
      this.selectedEventSeveritiesAndTypes[deviceId] = {};
    }
    if (!this.selectedEventSeveritiesAndTypes[deviceId]![category]) {
      this.selectedEventSeveritiesAndTypes[deviceId]![category] = { isEventCategorySelected: false };
    }
    this.selectedEventSeveritiesAndTypes[deviceId]![category]![eventType] = active;
  };

  toggleSelectedEventTypeForDevice = ({
    deviceId,
    category,
    eventType,
  }: {
    deviceId: string;
    category: EventCategory;
    eventType: string;
  }) => {
    if (!this.selectedEventSeveritiesAndTypes[deviceId]) {
      this.selectedEventSeveritiesAndTypes[deviceId] = {};
    }
    if (!this.selectedEventSeveritiesAndTypes[deviceId]![category]) {
      this.selectedEventSeveritiesAndTypes[deviceId]![category] = { isEventCategorySelected: false };
    }
    this.selectedEventSeveritiesAndTypes[deviceId]![category]![eventType] =
      !this.selectedEventSeveritiesAndTypes[deviceId]![category]![eventType];
    // if all event types for category are selected, set category to selected
    const eventTypesForCategory = Object.keys(this.eventsSummary[deviceId][category]);
    const allEventTypesSelected = eventTypesForCategory.every(
      (eventType) => this.selectedEventSeveritiesAndTypes[deviceId]?.[category]?.[eventType]
    );

    if (allEventTypesSelected) {
      this.setSelectedCategoryForDevice({ deviceId, category, active: true });
    }

    // if event category has only one event type and it's not selected, set category to indeterminate
    if (eventTypesForCategory?.length === 1 && !allEventTypesSelected) {
      this.setSelectedCategoryForDevice({
        deviceId,
        category,
        active: false /* "indeterminate" */,
      });
    }

    // if only some event types for category are selected, set category to indeterminate
    const someEventSelected = eventTypesForCategory.some(
      (eventType) => this.selectedEventSeveritiesAndTypes[deviceId]?.[category]?.[eventType]
    );

    const someEventTypesSelected = someEventSelected && !allEventTypesSelected;
    if (someEventTypesSelected) {
      this.setSelectedCategoryForDevice({
        deviceId,
        category,
        active: false /* "indeterminate" */,
      });
    }
  };

  setSelectedCategoryForDevice = ({
    deviceId,
    category,
    active,
  }: {
    deviceId: string;
    category: EventCategory;
    active: boolean;
  }) => {
    if (!this.selectedEventSeveritiesAndTypes[deviceId]?.[category]) {
      this.selectedEventSeveritiesAndTypes[deviceId] = {
        [category]: { isEventCategorySelected: false },
      };
    }
    this.selectedEventSeveritiesAndTypes[deviceId]![category]!.isEventCategorySelected = active;
  };

  toggleSelectedCategoryForDevice = ({
    deviceId,
    category,
  }: {
    deviceId: string;
    category: EventCategory;
  }) => {
    if (!this.selectedEventSeveritiesAndTypes[deviceId]?.[category]) {
      if (!this.selectedEventSeveritiesAndTypes[deviceId]) {
        this.selectedEventSeveritiesAndTypes[deviceId] = {};
      }

      this.selectedEventSeveritiesAndTypes[deviceId]![category] = { isEventCategorySelected: false };
    }

    this.selectedEventSeveritiesAndTypes[deviceId]![category]!.isEventCategorySelected =
      !this.selectedEventSeveritiesAndTypes[deviceId]?.[category]?.isEventCategorySelected;
    const eventTypesToToggle = Object.keys(this.eventsSummary[deviceId][category]);
    // if category has been set to selected, set all event types to selected
    if (this.selectedEventSeveritiesAndTypes[deviceId]?.[category]?.isEventCategorySelected) {
      eventTypesToToggle.forEach((eventType) => {
        this.setSelectedEventTypeForDevice({ deviceId, category: category, eventType, active: true });
      });
    }

    // if category has been set to unselected, set all event types to unselected
    if (!this.selectedEventSeveritiesAndTypes[deviceId]?.[category]?.isEventCategorySelected) {
      eventTypesToToggle.forEach((eventType) => {
        this.setSelectedEventTypeForDevice({ deviceId, category: category, eventType, active: false });
      });
    }
  };

  setEventsTimeSeriesData = (eventsTimeSeriesData: {
    [deviceId: string]: FormattedTimeSeriesTelemetryModelDeviceEventLog;
  }) => {
    this.eventsTimeSeriesData = eventsTimeSeriesData;
  };

  isEventTypeSelectedForDevice = ({
    deviceId,
    category,
    eventType,
  }: {
    deviceId: string;
    category: EventCategory;
    eventType: string;
  }) => {
    return this.selectedEventSeveritiesAndTypes[deviceId]?.[category]?.[eventType];
  };

  isCategorySelectedForDevice = ({ deviceId, category }: { deviceId: string; category: EventCategory }) => {
    return this.selectedEventSeveritiesAndTypes[deviceId]?.[category]?.isEventCategorySelected;
  };

  removeDeviceFromEventsGraph = (deviceId: string) => {
    const filteredEventsTimeSeriesData = Object.entries(this.eventsTimeSeriesData).reduce<{
      [deviceId: string]: FormattedTimeSeriesTelemetryModelDeviceEventLog;
    }>((acc, [id, data]) => {
      if (id !== deviceId) acc[id] = data;
      return acc;
    }, {});

    this.setEventsTimeSeriesData(filteredEventsTimeSeriesData);
  };

  clearAdvancedParamsData = () => (this.advancedParamsData = []);

  setSelectedDate = (date: Date) => {
    this.historyTraversal.stepsBack = 0;
    this.dataRequestHistory = [];
    this.selectedDate = date;
  };

  setDeviceActivityData = (data: TimeSeries) => {
    this.deviceActivityData = data;
  };

  clearDeviceActivityData = () => (this.deviceActivityData = []);

  setSelectedActivityDevice = (deviceId?: string) => (this.selectedActivityDevice = deviceId);

  setHoveredTimestamp = (timestamp?: string) => {
    this.hoveredTimestamp = timestamp ? Number(timestamp) : undefined;
  };

  getLatestActivityData = async ({
    selectedDeviceId,
    timezoneStr,
  }: {
    selectedDeviceId: string;
    timezoneStr: string;
  }) => {
    const { startTime } = getStartAndEndTime(this.selectedDate);

    const device = this.selectedDevices[selectedDeviceId]?.device;
    const { id, deviceType } = device;
    const deviceActivityData = await getAnalyticsDeviceActivity(
      deviceType,
      id,
      this.selectedDate,
      6,
      timezoneStr || this.timezoneStr
    );

    const { filledTimeSeries, finalStartTime, firstActivityTime, initialStartTime } = createTimeSeries(
      timezoneStr,
      deviceActivityData,
      id,
      startTime,
      this.selectedDate
    );

    runInAction(() => {
      this.setActivityTime(firstActivityTime, finalStartTime);
      this.setDeviceActivityData(filledTimeSeries);
      if (!initialStartTime) {
        toast.info(`Activity not found for selected Device`, { duration: 4000 });
        console.info(`No activity found for ${id} on ${this.selectedDate}`);
      }
    });
  };

  getDeviceActivityData = async ({
    timezoneStr,
    selectedDeviceId,
    selectedDevice,
    specificTimespan,
  }: {
    timezoneStr: string | undefined;
    selectedDeviceId?: string;
    selectedDevice?: UiDevice;
    specificTimespan?: { startTime: number | Date; endTime: number | Date };
  }) => {
    this.clearAdvancedParamsData();
    if (!this.selectedDate) return;
    // Commented out to prevent clearing of device activity data on new params selection
    // this.clearDeviceActivityData();
    // this.setDeviceActivityLoaded(false);
    // Commented out to prevent clearing of device activity data on new params selection

    this.setIsLoadingDataFromServer(true);
    this.setIsLoadingGraphData(true);
    const { startTime } = getStartAndEndTime(this.selectedDate);
    const device =
      (selectedDeviceId && this.selectedDevices[selectedDeviceId]?.device) ||
      this.deviceForActivityGraph ||
      selectedDevice;

    if (!device) {
      this.setIsLoadingDataFromServer(false);
      this.setIsLoadingGraphData(false);
      console.error('No device selected for activity graph');
      return;
    }

    const { id, deviceType } = device;
    this.setSelectedActivityDevice(id);

    const deviceActivityData = await getAnalyticsDeviceActivity(
      deviceType,
      id,
      this.selectedDate,
      6,
      timezoneStr || this.timezoneStr
    );
    this.setDeviceActivityLoaded(true);

    const defaultStartTime = set(this.selectedDate, {
      hours: this.defaultStartHour,
      minutes: this.defaultStartMinute || 0,
    });
    const defaultEndTime = set(this.selectedDate, {
      hours: this.defaultEndHour,
      minutes: this.defaultEndMinute || 0,
    });

    // fills missing data points to create a series of 36 hours with minute intervals
    const { filledTimeSeries, initialStartTime, finalStartTime, firstActivityTime } = createTimeSeries(
      timezoneStr,
      deviceActivityData,
      id,
      startTime,
      this.selectedDate
    );

    if (device && this.selectedTimespanValues && this.deviceActivityData.length) {
      runInAction(async () => {
        await this.getDataForSelectedTimespan(
          this.selectedTimespanValues.startTime,
          this.selectedTimespanValues.endTime
        );
        this.setIsLoadingGraphData(false);
        this.setIsLoadingDataFromServer(false);
      });
      return;
    }

    runInAction(async () => {
      this.setActivityTime(firstActivityTime, finalStartTime);
      this.setDeviceActivityData(filledTimeSeries);
      if (!initialStartTime && !specificTimespan) {
        toast.info(`Activity not found for selected Device`, { duration: 4000 });
        console.info(`No activity found for ${id} on ${this.selectedDate}`);
        console.info(`Retreiving data for default timespan: ${defaultStartTime} - ${defaultEndTime}`);
        if (!defaultStartTime || !defaultEndTime) {
          console.info(`No default time found, applying full timespan`);
        }

        this.setSelectedTimespan(defaultStartTime, defaultEndTime);
        await this.getDataForSelectedTimespan(defaultStartTime, defaultEndTime);
      }

      if (specificTimespan) {
        const { startTime, endTime } = specificTimespan;
        this.setSelectedTimespan(startTime, endTime);
        await this.getDataForSelectedTimespan(startTime, endTime);
      } else if (initialStartTime && finalStartTime) {
        this.setSelectedTimespan(initialStartTime, finalStartTime);
        await this.getDataForSelectedTimespan(initialStartTime, finalStartTime);
      }

      runInAction(() => {
        this.setIsLoadingGraphData(false);
        this.setIsLoadingDataFromServer(false);
      });
    });
  };

  private adjoiningActivityDay = async (timezoneStr: string, direction: number) => {
    if (!timezoneStr) {
      console.error('No timezone provided');
      return;
    }
    if (!this.selectedDate) {
      console.error('No date selected');
      return;
    }
    if (direction !== 1 && direction !== -1) {
      return;
    }
    this.setIsLoadingDataFromServer(true);
    this.setIsLoadingGraphData(true);
    const adjoiningDay = direction === 1 ? addDays(this.selectedDate, 1) : subDays(this.selectedDate, 1);

    this.clearAdvancedParamsData();
    this.clearDeviceActivityData();
    this.setSelectedDate(adjoiningDay);
    await this.getDeviceActivityData({ timezoneStr, selectedDeviceId: this.selectedActivityDevice });
    await this.getSummaryTableData(timezoneStr);
    runInAction(() => {
      this.setIsLoadingDataFromServer(false);
      this.setIsLoadingGraphData(false);
    });
  };

  getPreviousActivityDay = (timezoneStr: string) => {
    return this.adjoiningActivityDay(timezoneStr, -1);
  };

  getNextActivityDay = (timezoneStr: string) => {
    return this.adjoiningActivityDay(timezoneStr, 1);
  };

  setSelectedTimespan: SetSelectedTimespan = (start, end) => {
    if (!start && !end) {
      console.error('start and end time not selected');
      return;
    }
    const startIndex = this.deviceActivityData.findIndex(
      (el) => el.timestamp === set(start!, { seconds: 0, milliseconds: 0 }).valueOf()
    );
    const endIndex = this.deviceActivityData.findIndex(
      (el) => el.timestamp === set(end!, { seconds: 0, milliseconds: 0 }).valueOf()
    );

    this.selectedTimespanIndices = { startIndex, endIndex };

    const startTime = start || this.deviceActivityData[startIndex || 0]?.timestamp;
    const endTime = end || this.deviceActivityData[endIndex || this.deviceActivityData.length - 1]?.timestamp;

    console.info(`Setting selected timespan for ${startTime} to ${endTime}`);

    this.selectedTimespanValues = {
      startTime: startTime ?? 0,
      endTime: endTime ?? 0,
    };
  };

  get deviceForActivityGraph() {
    const sortedDevices = Object.values(this.selectedDevices).sort((a, b) =>
      a.device.id && b.device.id && a.device.id.slice(2) > b.device.id.slice(2) ? 1 : -1
    );
    const firstVu = sortedDevices.find(({ device }) => device.deviceType === 'VU');

    if (firstVu) return firstVu.device;
    const firstMu = sortedDevices.find(({ device }) => device.deviceType === 'MU');
    if (firstMu) return firstMu.device;

    const firstOcpp = sortedDevices.find(({ device }) => device.deviceType === 'OCPP');
    if (firstOcpp) return firstOcpp.device;
    return null;
  }

  get selectedParamCount() {
    let count = 0;
    Object.values(this.selectedDevices).forEach((device) => {
      count += device.selectedParameters.size;
    });
    return count;
  }

  @computed
  get eventTypeToCategory() {
    const eventTypeToCategory = new Map<string, EventCategory>();
    Object.entries(this.eventsSummary).forEach(([deviceId, deviceEvents]) => {
      Object.entries(deviceEvents).forEach(([eventCategory, eventTypes]) => {
        if (!isEventCategory(eventCategory)) throw new Error('Invalid event category');
        Object.keys(eventTypes).forEach((eventType) => {
          eventTypeToCategory.set(eventType, eventCategory);
        });
      });
    });
    return eventTypeToCategory;
  }

  @computed
  get sharedUrl() {
    const paramsArr: Array<{ id: string; params: Set<string> }> = [];
    Object.values(this.selectedDevices).forEach((el) => {
      paramsArr.push({ id: el.device.id, params: el.selectedParameters });
    });

    const sharedUrlData: SharedUrlData = {
      paramsArr: paramsArr,
      selectedDate: this.selectedDate,
      selectedTimespanValues: this.selectedTimespanValues,
      selectedActivityDevice: this.selectedActivityDevice,
      selectedEventSeveritiesAndTypes: this.selectedEventSeveritiesAndTypes,
      stickyRefLinesObj: this.stickyRefLinesObj,
      userTz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      dutyCycleObj: this.dutyCycleObj,
    };
    if (isValidSharedParams(sharedUrlData)) {
      const paramsString = `${JSON.stringify(sharedUrlData.paramsArr)}`;
      const dateString = `${JSON.stringify(sharedUrlData.selectedDate)}`;
      const selectedTimespan = `${JSON.stringify(sharedUrlData.selectedTimespanValues)}`;
      const activityDeviceId = `${JSON.stringify(sharedUrlData.selectedActivityDevice)}`;
      const selectedEvent = `${JSON.stringify(sharedUrlData.selectedEventSeveritiesAndTypes)}`;
      const stickyMarkers = `${JSON.stringify(sharedUrlData.stickyRefLinesObj)}`;
      const userTz = `${JSON.stringify(sharedUrlData.userTz)}`;
      const dutyCycle = `${JSON.stringify(sharedUrlData.dutyCycleObj)}`;
      const searchParams = new URLSearchParams();
      searchParams.set('d', dateString);
      searchParams.set('q', paramsString);
      searchParams.set('t', selectedTimespan);
      searchParams.set('a', activityDeviceId);
      searchParams.set('e', selectedEvent);
      searchParams.set('m', stickyMarkers);
      searchParams.set('tz', userTz);
      searchParams.set('dc', dutyCycle);
      const location = `${window.location.origin}/dashboard/history/project/${this.rootStore.projectStore.selectedProject?.id}`;
      const url = new URL(`${location.split('?')[0] || location}?${searchParams}`);
      return url;
    } else {
      return window.location.href;
    }
  }

  @computed
  get paramMaps() {
    return transformAnalyticsParams(this.projectParams);
  }

  removeAdvancedParam = (id: DataGraphParameterType, deviceId?: string, filters?: Record<any, any>) => {
    if (!id || !deviceId) {
      console.error('No id or deviceId provided');
      return;
    }
    const filteredParams = this.advancedParamsData.filter((el) => {
      const elFilters = el?.filters;
      let stringifiedElFilters = elFilters ? JSON.stringify(elFilters) : null;
      const isSameDevice = el.deviceId === deviceId;
      const isSameParam = el?.param?.id === id;
      const isSameFilters = stringifiedElFilters === JSON.stringify(filters) || elFilters === filters;

      if (isSameDevice && isSameParam && isSameFilters) {
        return false;
      }
      return true;
    });

    this.setAdvancedParamsData(filteredParams);
  };

  getDataForSelectedTimespan: GetDataForSelectedTimespan = async (
    zoomStart,
    zoomEnd,
    isHistoryTraversing
  ) => {
    const timezoneStr = this.rootStore.projectStore.selectedProject?.timezoneStr || 'Asia/Jerusalem';
    if (!isHistoryTraversing) {
      this.dataRequestHistory.push([zoomStart, zoomEnd, true]);
    }

    const { startTime, endTime, advancedParamsRequestData } = prepareDataGraphParams({
      selectedDevices: this.selectedDevices,
      zoomStart,
      zoomEnd,
    });

    if (advancedParamsRequestData.length === 0) return;

    this.setIsLoadingGraphData(true);
    const currentRequestId = uuidv4();
    this.graphDataRequestId = currentRequestId;

    try {
      const [graphsData, eventsSummaryData] = await Promise.all([
        fetchAdvancedParamsData(advancedParamsRequestData, startTime, endTime, timezoneStr),
        this.rootStore.queryClient.fetchQuery({
          queryKey: [
            'analytics:eventsSummary',
            startTime,
            endTime,
            Object.keys(this.selectedDevices),
            timezoneStr,
          ],
          queryFn: () =>
            api.deviceTelemetry.AnalyticsApi.getEventsSummary(
              startTime,
              endTime,
              Object.keys(this.selectedDevices),
              timezoneStr
            ),
          staleTime: Infinity,
        }),
      ]);

      // if it's the first request, set the default selection for severities and event types
      if (Object.keys(this.selectedEventSeveritiesAndTypes).length === 0) {
        this.setDefaultEventSelection(eventsSummaryData.data);
      }

      const selectedEvents = getSelectedEventsFromSummary(
        eventsSummaryData.data,
        this.selectedEventSeveritiesAndTypes
      );

      const eventsResponse =
        selectedEvents.length === 0
          ? null
          : await getEventsTimeSeries(
              startTime,
              endTime,
              Object.keys(this.selectedDevices),
              selectedEvents,
              timezoneStr,
              true
            );

      if (!graphsData || graphsData.length === 0) {
        this.setIsLoadingGraphData(false);
        toast.info(`No graphs data found for selected timespan`, { duration: 4000 });
        console.info('No graphs data found for selected timespan');
        return;
      }

      this.dataToExport = [];
      this.setIsZooming(false);
      this.setTimeRulerBorder(true, true);

      const {
        normalizedData: { eventsData, advancedParamsData },
        timestampsArray,
      } = normalizeTimeSeriesLengths(graphsData, eventsResponse?.data, eventsSummaryData.data);

      runInAction(() => {
        if (this.graphDataRequestId === currentRequestId) {
          this.selectedTimeRange = timestampsArray;
          this.setEventsTimeSeriesData(eventsData);
          this.setAdvancedParamsData(advancedParamsData);
          this.setEventsSummary(eventsSummaryData.data);
          this.setIsLoadingGraphData(false);
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  getSummaryTableData: GetSummaryTableData = async (timezoneStr, zoomStart, zoomEnd) => {
    if (!timezoneStr) {
      console.info('No timezone provided, skipping summary table data fetch');
      return;
    }

    const selectedActivityDeviceType =
      this.selectedDevices[this.selectedActivityDevice || this.selectedVuIds[0]]?.device?.deviceType;
    const deviceToDisplay =
      selectedActivityDeviceType === IoTDeviceType.Mu && this.selectedVuIds?.length
        ? this.selectedDevices[this.selectedVuIds[0]]
        : this.selectedDevices[this.selectedActivityDevice!];

    if (!deviceToDisplay) {
      console.info('No device selected for summary table data');
      return;
    }
    const { name: deviceToDisplayName, id: deviceToDisplayId } = deviceToDisplay?.device || {};

    const selectedMuIds = this.selectedMuIds.length ? this.selectedMuIds : ['null'];

    const zoomStartTime = zoomStart ? getDateTimeFormatWithMilliseconds(zoomStart) : null;
    const zoomEndTime = zoomEnd ? getDateTimeFormatWithMilliseconds(zoomEnd) : null;

    if (deviceToDisplayId && deviceToDisplayName) {
      this.setIsLoadingDataFromServer(true);

      const startTime =
        zoomStartTime || getDateTimeFormatWithMilliseconds(this.selectedTimespanValues.startTime);
      const endTime = zoomEndTime || getDateTimeFormatWithMilliseconds(this.selectedTimespanValues.endTime);
      const currentRequestId = uuidv4();
      this.summaryTableRequestId = currentRequestId;

      try {
        const { data: tableData } = await getTableData(
          selectedMuIds,
          [deviceToDisplayId],
          startTime,
          endTime,
          timezoneStr
        );

        runInAction(() => {
          if (tableData && this.summaryTableRequestId === currentRequestId) {
            this.setProjectAnalyticsSummaryTableData(
              getFormattedTableData(
                tableData,
                deviceToDisplayId,
                convertFormattedTimeToMiliseconds(tableData[deviceToDisplayId].timeSpan || '') / 1000,
                deviceToDisplayName
              )
            );
            this.setIsLoadingDataFromServer(false);
          }
        });
      } catch (e) {
        console.error(String(e));
        this.setIsLoadingDataFromServer(false);
      }
    }
  };

  zoomTimespan = (startTime: Date | number, endTime: Date | number) => {
    this.selectedTimespanValues = {
      startTime,
      endTime,
    };
  };

  setTimeRulerBorder = (left: boolean, right: boolean) => {
    this.showTimeRulerBorder.left = left;
    this.showTimeRulerBorder.right = right;
  };

  setIsZooming = (zoom: boolean) => (this.isZooming = zoom);

  setTimeValuesBeforeZoom = (start: Date | number, end: Date | number) => {
    this.timeValuesBeforeZoom.start = start;
    this.timeValuesBeforeZoom.end = end;
  };

  setIsExportingRawData = (isExporting: boolean) => {
    this.isExportingRawData = isExporting;
  };

  setIsRawDataExportError = (isError: boolean) => {
    this.isRawDataExportError = isError;
  };

  setIsRawDataExportSuccess = (isSuccess: boolean) => {
    this.isRawDataExportSuccess = isSuccess;
  };

  setActivityTime = (start?: number, end?: number) => {
    this.activityTime.start = start;
    this.activityTime.end = end;
  };

  setStickyRefLines = ({
    deviceId,
    graphId,
    refLine,
    sharedStickyMarkersData,
    filters,
  }: {
    deviceId?: string;
    graphId?: string;
    refLine?: StickyRefLineItem;
    sharedStickyMarkersData?: SharedUrlData['stickyRefLinesObj'];
    filters?: string;
  }) => {
    if (sharedStickyMarkersData) {
      this.stickyRefLinesObj = sharedStickyMarkersData;
    } else if (!sharedStickyMarkersData && refLine) {
      if (this.stickyRefLinesObj?.[`${deviceId}_${graphId}_${filters}`]) {
        this.stickyRefLinesObj?.[`${deviceId}_${graphId}_${filters}`].push(refLine);
      } else {
        this.stickyRefLinesObj = {
          ...this.stickyRefLinesObj,
          [`${deviceId}_${graphId}_${filters}`]: [refLine],
        };
      }
    }
  };

  undoStickyRefLine = (deviceId: string, graphId: string, filters?: string) => {
    this.stickyRefLinesObj = {
      ...this.stickyRefLinesObj,
      [`${deviceId}_${graphId}_${filters}`]: this.stickyRefLinesObj?.[
        `${deviceId}_${graphId}_${filters}`
      ]?.slice(0, -1),
    };
  };

  clearStickyRefLines = () => {
    this.stickyRefLinesObj = {};
  };

  get stickyRefLines() {
    return this.stickyRefLinesObj;
  }

  get dutyCycle() {
    return this.dutyCycleObj;
  }

  setDutyCycle = ({
    dutyCycle,
    graphHash,
    sharedDutyCycleData,
  }: {
    dutyCycle?: DutyCycleItem;
    graphHash?: string;
    sharedDutyCycleData?: SharedUrlData['dutyCycleObj'];
  }) => {
    if (sharedDutyCycleData) {
      this.dutyCycleObj = sharedDutyCycleData;
    } else if (graphHash && dutyCycle) {
      this.dutyCycleObj = {
        ...this.dutyCycleObj,
        [graphHash]: dutyCycle,
      } as SharedUrlData['dutyCycleObj'];
    }
  };

  private setDefaultEventSelection(eventsSummaryData: AnalyticsEventSummary) {
    Object.entries(eventsSummaryData).forEach(([deviceId, categoryToEventTypes]) => {
      this.selectedEventSeveritiesAndTypes[deviceId] = DEVAULT_CATEGORY_SELECTION;
      Object.entries(DEVAULT_CATEGORY_SELECTION || {}).forEach(([category, { isEventCategorySelected }]) => {
        if (isEventCategorySelected && isEventCategory(category)) {
          Object.keys(categoryToEventTypes[category] || {}).forEach((eventType) => {
            this.setSelectedEventTypeForDevice({ deviceId, category: category, eventType, active: true });
          });
        }
      });
    });
  }

  cleanup() {
    this.selectedDevices = {};
    this.projectParams = {};
    this.projectAnalyticsSummaryTableData = {};
    this.dataToExport = [];
    this.isLoadingDataFromServer = false;
    this.isLoadingGraphData = false;
    this.advancedParamsData = [];
    this.selectedDate = set(
      utcToZonedTime(
        new Date(),
        this.rootStore.projectStore?.selectedProject?.timezoneStr || 'Asia/Jerusalem'
      ),
      { milliseconds: 0 }
    );
    this.deviceActivityData = [];
    this.selectedActivityDevice = '';
    this.selectedTimespanIndices = { startIndex: 0, endIndex: 0 };
    this.selectedTimespanValues = {
      startTime: utcToZonedTime(
        new Date(),
        this.rootStore.projectStore?.selectedProject?.timezoneStr || 'Asia/Jerusalem'
      ).valueOf(),
      endTime: utcToZonedTime(
        new Date(),
        this.rootStore.projectStore?.selectedProject?.timezoneStr || 'Asia/Jerusalem'
      ).valueOf(),
    };
    this.selectedTimeRange = [];
    this.deviceActivityLoaded = false;
    this.eventsSummary = {};
    this.eventsTimeSeriesData = {};
    this.stickyRefLinesObj = {};
  }
}
