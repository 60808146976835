import { Autocomplete, Box, Button, Divider, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
  getOptionalUserRoles,
  getUserProjectsScope,
  UserAuthorityInput,
  UserAuthoritySchema,
} from './ManageUsersFormsUtils';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormSubmitionButtons } from 'Components/Buttons/FormSubmitAndCancelButtons/FormSubmitionButtons';
import { useAppStore } from 'MobxStores/context';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { toast } from 'sonner';
import { ProjectMultiSelectionFormInput } from '../ProjectSelectionInputs/ProjectMultiSelectionFormInput';
import { theme } from '@electreon_ui/shared/Themes/globalTheme';
import { isRoleHigher, isUserRole } from 'Utils/utils';
import { UserRole } from '@electreon_ui/shared/constants/constants';

export const UserAuthorityForm: React.FC<FormProps> = observer(({ onSuccessfulSubmit, onCancel }) => {
  const [loading, setLoading] = useState(false);
  const { userStore, authStore } = useAppStore();
  const methods = useForm<UserAuthorityInput>({
    resolver: zodResolver(UserAuthoritySchema),
    defaultValues: {
      role: '',
      selectedProjects: [],
      username: '',
    },
  });
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = methods;
  const [isGlobalScopedUser, setIsGlobalScopedUser] = useState<boolean>(false);
  const [canChangeRole, setCanChangeRole] = useState<boolean>(false);

  const onSubmitHandler: SubmitHandler<UserAuthorityInput> = (values) => {
    setLoading(true);
    const { selectedProjects, role, username } = values;
    const userScope = selectedProjects;
    userScope &&
      authStore
        .updateUserScope(username, userScope)
        .then(() => {
          toast.success('User Scope Updated');
        })
        .catch((error) => {
          console.error(error, 'error');
          toast.error('Something went wrong with updating the user Scope, please try again.');
        });

    role &&
      authStore
        .updateUserRole(username, role)
        .then(() => {
          toast.success('User Role Updated');
        })
        .catch(() => {
          toast.error('Something went wrong with updating the user Role, please try again.');
        });
    onSuccessfulSubmit();
  };
  const optionalUserRoles = getOptionalUserRoles(userStore.userData?.role);

  const getUserHandler = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    const email = methods.getValues('email');
    authStore.getUserData(email).then((userData) => {
      if (!userData || Array.isArray(userData)) {
        !userData && toast.error('There are no users found with this email.');
        Array.isArray(userData) &&
          toast.error('There are more than one user with this email. please contact the software team.');
        methods.reset({ username: '', selectedProjects: [], role: '' });
        setIsGlobalScopedUser(false);
        setCanChangeRole(false);
        return;
      }
      authStore
        .getUserRole(userData.Username)
        .then((res) => {
          const userProjectsScope = getUserProjectsScope(userData);
          const userRole = res?.userRole;
          if (!isUserRole(userRole)) {
            toast.error('Something went wrong with getting the user Role, please try again later.');
            methods.reset({ username: '', selectedProjects: [], role: '' });
            setIsGlobalScopedUser(false);
            setCanChangeRole(false);
            return;
          }
          methods.reset({
            selectedProjects: userProjectsScope,
            username: userData.Username,
            role: res?.userRole,
          });
          setIsGlobalScopedUser(Array.isArray(userProjectsScope) && userProjectsScope.length === 0);
          setCanChangeRole(isRoleHigher(userStore.userData?.role, userRole));
        })
        .catch((error) => {
          toast.error('Something went wrong with getting the user Data, please try again later.');
          methods.reset({ username: '', selectedProjects: [], role: '' });
          setIsGlobalScopedUser(false);
          setCanChangeRole(false);
        });
    });
  };

  return (
    <FormProvider {...methods}>
      <Typography variant='h4' component='h1'>
        Change User Authority
      </Typography>
      <Box
        component='form'
        noValidate
        autoComplete='off'
        sx={{ mt: 4 }}
        onSubmit={handleSubmit(onSubmitHandler, (errors) => {
          console.log(errors);
        })}
      >
        <Stack direction='column' spacing={2} sx={{ mb: 2 }}>
          <TextField
            label='User Email'
            fullWidth
            required
            error={!!errors['email']}
            helperText={errors['email'] ? errors['email'].message : ''}
            {...register('email')}
            onChange={() => {
              methods.reset({ username: '', selectedProjects: [], role: '' });
            }}
          />
          <Button variant='contained' onClick={getUserHandler}>
            Get User Data
          </Button>
        </Stack>
        <Divider sx={{ mt: 1, mb: 2 }} />
        <Autocomplete
          fullWidth
          options={optionalUserRoles}
          sx={{ mb: 2 }}
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              label='User Role'
              error={!!errors['role']}
              helperText={errors['role'] ? errors['role'].message : ''}
              {...register('role')}
            />
          )}
          value={methods.getValues('role')}
          onChange={(event, newValue) => {
            methods.setValue('role', newValue || undefined);
          }}
          disabled={!methods.getValues('username') || !canChangeRole}
        />
        <ProjectMultiSelectionFormInput disabled={!methods.getValues('username') || !canChangeRole} />
        {isGlobalScopedUser && (
          <Typography sx={{ mb: 2, color: theme.palette.accent.primary.main }}>
            Note: This User Has Global Projects Scope.
          </Typography>
        )}
        <FormSubmitionButtons
          loading={loading}
          onCancel={onCancel}
          submitLabel='Change Authority'
          confirmation={isGlobalScopedUser && methods.getValues('selectedProjects').length > 0}
          confirmTitle={'Are You Sure You Want To Change User Scope From Global?'}
          confirmMessage={'User will have access to specified projects only!'}
          onSubmitHandler={onSubmitHandler}
          disableSubmit={!methods.getValues('username') || !canChangeRole}
          {...methods.watch()}
        />
      </Box>
    </FormProvider>
  );
});
