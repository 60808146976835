import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { getOptionalUserRoles, InviteUserInput, InviteUserSchema } from './ManageUsersFormsUtils';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormSubmitionButtons } from 'Components/Buttons/FormSubmitAndCancelButtons/FormSubmitionButtons';
import { UserRole } from '@electreon_ui/shared/constants/constants';
import { useAppStore } from 'MobxStores/context';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { api } from 'Services/api';
import { toast } from 'sonner';
import { ProjectMultiSelectionFormInput } from '../ProjectSelectionInputs/ProjectMultiSelectionFormInput';

export const InviteUserForm: React.FC<FormProps> = observer(({ onSuccessfulSubmit, onCancel }) => {
  const [loading, setLoading] = useState(false);
  const { userStore } = useAppStore();
  const methods = useForm<InviteUserInput>({
    resolver: zodResolver(InviteUserSchema),
    defaultValues: {
      role: UserRole.PROJECT_VIEWER,
      selectedProjects: [],
    },
  });
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = methods;
  const onSubmitHandler: SubmitHandler<InviteUserInput> = (values) => {
    setLoading(true);
    const { email, firstName, familyName, selectedProjects, role } = values;
    const userScope = selectedProjects;
    api.userManagement.userManagementApi
      .registerNewUser({
        firstName,
        familyName,
        email,
        userScope,
        userRole: role || '',
      })
      .then(() => {
        toast.success('An Invite has been sent to the user email.');
      })
      .catch((error) => {
        if (error?.response?.data?.error?.includes('email already exists')) {
          toast.error('Email Already Exists.');
          return;
        }
        toast.error('Something went wrong, please try again.');
      });
    onSuccessfulSubmit();
  };
  const optionalUserRoles = getOptionalUserRoles(userStore.userData?.role);
  return (
    <FormProvider {...methods}>
      <Typography variant='h4' component='h1'>
        Invite a New User
      </Typography>
      <Box
        component='form'
        noValidate
        autoComplete='off'
        sx={{ mt: 4 }}
        onSubmit={handleSubmit(onSubmitHandler, (errors) => {
          console.log(errors);
        })}
      >
        <TextField
          sx={{ mb: 2 }}
          label='User First Name'
          fullWidth
          required
          error={!!errors['firstName']}
          helperText={errors['firstName'] ? errors['firstName'].message : ''}
          {...register('firstName')}
        />
        <TextField
          sx={{ mb: 2 }}
          label='User Family Name'
          fullWidth
          required
          error={!!errors['familyName']}
          helperText={errors['familyName'] ? errors['familyName'].message : ''}
          {...register('familyName')}
        />
        <TextField
          sx={{ mb: 2 }}
          label='User Email'
          fullWidth
          required
          error={!!errors['email']}
          helperText={errors['email'] ? errors['email'].message : ''}
          {...register('email')}
        />
        <Autocomplete
          fullWidth
          options={optionalUserRoles}
          sx={{ mb: 2 }}
          defaultValue={UserRole.PROJECT_VIEWER}
          renderInput={(params) => (
            <TextField
              {...params}
              label='User Role'
              error={!!errors['role']}
              helperText={errors['role'] ? errors['role'].message : ''}
              {...register('role')}
            />
          )}
        />
        <ProjectMultiSelectionFormInput />
        <FormSubmitionButtons loading={loading} onCancel={onCancel} submitLabel='Send Invite' />
      </Box>
    </FormProvider>
  );
});
