import { makeAutoObservable } from 'mobx';
import { AlertProps } from '@mui/material';
import { CSSProperties } from 'react';
import { IModalState, InformationPopupState, defaultModalState } from './popupStoreTypes';
import { UiDevice } from '../../types/globals';

class PopupStore {
  selectedDevice: UiDevice | null = null;
  selectedRoadId?: number;
  selectedDepotId?: number;
  selectedProjectId?: number;

  createDevicePopupOpen: boolean = false;
  editDevicePopupOpen: boolean = false;
  editPOSPopupOpen: boolean = false;
  editAPFCPopupOpen: boolean = false;
  editOCPPPopupOpen: boolean = false;
  assignOCPPPopupOpen: boolean = false;
  unassignDevicePopupOpen: boolean = false;
  deviceResetOptionsPopupOpen: boolean = false;
  deviceFotaPopupOpen: boolean = false;
  deviceSetEnergyMeterValuePopupOpen: boolean = false;
  createProjectPopupOpen: boolean = false;
  deleteProjectPopupOpen: boolean = false;
  configureDevicePopupOpen: boolean = false;
  appModalState: IModalState = defaultModalState;
  dashboardConfigurationPopupOpen: boolean = false;
  createDepotPopupOpen: boolean = false;
  editDepotPopupOpen: boolean = false;
  deleteDepotPopupOpen: boolean = false;
  manageParkingSpotsPopupOpen: boolean = false;
  editProjectPopupOpen: boolean = false;
  createRoadPopupOpen: boolean = false;
  editRoadPopupOpen: boolean = false;
  manageRoadPopupOpen: boolean = false;
  alertManagementPopupOpen: boolean = false;
  userAttributesPopupOpen: boolean = false;
  chargingSchedulePopupOpen: boolean = false;
  sdCardDownloadPopupOpen: boolean = false;
  displayNotificationsPopupOpen: boolean = false;
  firmwareDownloadPopupOpen: boolean = false;
  projectSelectionPopupOpen: boolean = false;
  inviteUserPopupOpen: boolean = false;
  updateUserPopupOpen: boolean = false;
  userAccountActivationPopupOpen: boolean = false;
  deleteUserPopupOpen: boolean = false;
  createFleetPopupOpen: boolean = false;

  informationPopup: InformationPopupState = {
    isOpen: false,
    severity: 'success',
    message: null,
    autoHideDuration: 4000,
    close: () => {
      this.informationPopup.isOpen = false;
    },
    width: '100%',
    handleClickAway: true,
  };

  constructor() {
    makeAutoObservable(this);
  }

  setSelectedDevice(device: UiDevice | null) {
    this.selectedDevice = device;
  }

  displayAlertMessage = ({
    message,
    duration,
    severity,
    anchorOrigin,
    width,
    handleClickAway = true,
  }: {
    message: string | JSX.Element;
    duration?: number;
    severity?: AlertProps['severity'];
    anchorOrigin?: {
      vertical?: 'top' | 'bottom';
      horizontal?: 'left' | 'center' | 'right';
    };
    width?: CSSProperties['width'];
    handleClickAway?: boolean;
  }) => {
    this.informationPopup.isOpen = true;
    this.informationPopup.message = message;
    this.informationPopup.autoHideDuration = duration || 1000 * 60 * 5;
    this.informationPopup.severity = severity || 'warning';
    this.informationPopup.anchorOrigin = anchorOrigin;
    this.informationPopup.width = width;
    this.informationPopup.handleClickAway = handleClickAway;
  };

  hideAlertMessage = () => {
    this.informationPopup.isOpen = false;
  };

  openCreateRoadPopup = () => {
    this.closePopups();
    this.createRoadPopupOpen = true;
  };

  closeCreateRoadPopup = () => {
    this.createRoadPopupOpen = false;
  };

  openEditRoadPopup = (roadId: number) => {
    this.closePopups();
    this.selectedRoadId = roadId;
    this.editRoadPopupOpen = true;
  };

  closeEditRoadPopup = () => {
    this.editRoadPopupOpen = false;
  };

  openManageRoadPopup = (roadId: number) => {
    this.closePopups();
    this.selectedRoadId = roadId;
    this.manageRoadPopupOpen = true;
  };

  closeManageRoadPopup = () => {
    this.manageRoadPopupOpen = false;
  };

  openCreateDepotPopup = () => {
    this.closePopups();
    this.createDepotPopupOpen = true;
  };

  closeCreateDepotPopup = () => {
    this.createDepotPopupOpen = false;
  };

  openEditDepotPopup = (depotId: number) => {
    this.closePopups();
    this.selectedDepotId = depotId;
    this.editDepotPopupOpen = true;
  };

  closeEditDepotPopup = () => {
    this.editDepotPopupOpen = false;
  };

  openDeleteDepotPopup = (depotId: number) => {
    this.closePopups();
    this.selectedDepotId = depotId;
    this.deleteDepotPopupOpen = true;
  };

  closeDeleteDepotPopup = () => {
    this.deleteDepotPopupOpen = false;
  };

  openManageParkingSpotsPopup = (device: UiDevice) => {
    this.closePopups();
    this.selectedDevice = device;
    this.manageParkingSpotsPopupOpen = true;
  };

  closeManageParkingSpotsPopup = () => {
    this.manageParkingSpotsPopupOpen = false;
  };

  closeCreateDevicePopup = () => {
    this.createDevicePopupOpen = false;
  };

  openCreateDevicePopup = () => {
    this.closePopups();
    this.createDevicePopupOpen = true;
  };

  closeEditDevicePopup = () => {
    this.editDevicePopupOpen = false;
  };

  openEditDevicePopup = (device: UiDevice) => {
    this.closePopups();
    this.selectedDevice = device;
    this.editDevicePopupOpen = true;
  };

  closeEditPOSPopup = () => {
    this.editPOSPopupOpen = false;
  };

  openEditPOSPopup = () => {
    this.closePopups();
    this.editPOSPopupOpen = true;
  };

  closeEditAPFCPopup = () => {
    this.editAPFCPopupOpen = false;
  };

  openEditAPFCPopup = () => {
    this.closePopups();
    this.editAPFCPopupOpen = true;
  };

  closeEditOCPPPopup = () => {
    this.editOCPPPopupOpen = false;
  };

  openEditOCPPPopup = () => {
    this.closePopups();
    this.editOCPPPopupOpen = true;
  };

  closeAssignOCPPPopup = () => {
    this.assignOCPPPopupOpen = false;
  };

  openAssignOCPPPopup = (device: UiDevice) => {
    this.closePopups();
    this.selectedDevice = device;
    this.assignOCPPPopupOpen = true;
  };

  closeUnassignDevicePopup = () => {
    this.unassignDevicePopupOpen = false;
  };

  openUnassignDevicePopup = (device: UiDevice) => {
    this.closePopups();
    this.selectedDevice = device;
    this.unassignDevicePopupOpen = true;
  };

  openDeviceResetOptionsPopup = (device: UiDevice) => {
    this.closePopups();
    this.selectedDevice = device;
    this.deviceResetOptionsPopupOpen = true;
  };

  closeDeviceResetOptionsPopup = () => {
    this.deviceResetOptionsPopupOpen = false;
  };

  openDeviceFotaPopup = (device: UiDevice) => {
    this.closePopups();
    this.selectedDevice = device;
    this.deviceFotaPopupOpen = true;
  };

  closeDeviceFotaPopup = () => {
    this.deviceFotaPopupOpen = false;
  };

  openDeviceSetEnergyMeterValuePopup = (device: UiDevice) => {
    this.closePopups();
    this.selectedDevice = device;
    this.deviceSetEnergyMeterValuePopupOpen = true;
  };

  closeDeviceSetEnergyMeterValuePopup = () => {
    this.deviceSetEnergyMeterValuePopupOpen = false;
  };

  closeCreateProjectPopup = () => {
    this.createProjectPopupOpen = false;
  };

  openCreateProjectPopup = () => {
    this.closePopups();
    this.createProjectPopupOpen = true;
  };

  openDeleteProjectPopup = () => {
    this.closePopups();
    this.deleteProjectPopupOpen = true;
  };

  closeDeleteProjectPopup = () => {
    this.deleteProjectPopupOpen = false;
  };

  openEditProjectPopup = () => {
    this.closePopups();
    this.editProjectPopupOpen = true;
  };

  closeEditProjectPopup = () => {
    this.editProjectPopupOpen = false;
  };

  openConfigureDevicePopup = (device: UiDevice) => {
    this.closePopups();
    this.selectedDevice = device;
    this.configureDevicePopupOpen = true;
  };

  closeConfigureDevicePopup = () => {
    this.configureDevicePopupOpen = false;
  };

  setAppModalState = (modalState: IModalState) => {
    this.closePopups();
    this.appModalState = modalState;
  };

  openDashboardConfigurationPopup = () => {
    this.closePopups();
    this.dashboardConfigurationPopupOpen = true;
  };

  closeDashboardConfigurationPopup = () => {
    this.dashboardConfigurationPopupOpen = false;
  };

  openUserAttributesPopup = () => {
    this.closePopups();
    this.userAttributesPopupOpen = true;
  };

  closeUserAttributesPopup = () => {
    this.userAttributesPopupOpen = false;
  };

  openChargingSchedulePopup = () => {
    this.closePopups();
    this.chargingSchedulePopupOpen = true;
  };

  closeChargingSchedulePopup = () => {
    this.chargingSchedulePopupOpen = false;
  };

  openSdCardDownloadPopup = (device: UiDevice) => {
    this.closePopups();
    this.selectedDevice = device;
    this.sdCardDownloadPopupOpen = true;
  };

  closeSdCardDownloadPopup = () => {
    this.sdCardDownloadPopupOpen = false;
  };

  openNotificationsPopup = () => {
    this.closePopups();
    this.displayNotificationsPopupOpen = true;
  };

  closeNotificationsPopup = () => {
    this.displayNotificationsPopupOpen = false;
  };

  openFirmwareDownloadPopup = () => {
    this.closePopups();
    this.firmwareDownloadPopupOpen = true;
  };

  closeFirmwareDownloadPopup = () => {
    this.firmwareDownloadPopupOpen = false;
  };

  openProjectSelectionPopup = () => {
    this.closePopups();
    this.projectSelectionPopupOpen = true;
  };

  closeProjectSelectionPopup = () => {
    this.projectSelectionPopupOpen = false;
  };

  openInviteUserPopup = (projectId?: number) => {
    this.closePopups();
    this.selectedProjectId = projectId;
    this.inviteUserPopupOpen = true;
  };

  closeInviteUserPopup = () => {
    this.inviteUserPopupOpen = false;
  };

  openUpdateUserPopup = () => {
    this.closePopups();
    this.updateUserPopupOpen = true;
  };

  closeUpdateUserPopup = () => {
    this.updateUserPopupOpen = false;
  };

  openUserAccountActivationPopup = () => {
    this.closePopups();
    this.userAccountActivationPopupOpen = true;
  };

  closeUserAccountActivationPopup = () => {
    this.userAccountActivationPopupOpen = false;
  };

  openDeleteUserPopup = () => {
    this.closePopups();
    this.deleteUserPopupOpen = true;
  };

  closeDeleteUserPopup = () => {
    this.deleteUserPopupOpen = false;
  };

  openCreateFleetPopup = () => {
    this.closePopups();
    this.createFleetPopupOpen = true;
  };

  closeCreateFleetPopup = () => {
    this.createFleetPopupOpen = false;
  };

  closePopups = () => {
    this.createDevicePopupOpen = false;
    this.editDevicePopupOpen = false;
    this.editPOSPopupOpen = false;
    this.editAPFCPopupOpen = false;
    this.editOCPPPopupOpen = false;
    this.assignOCPPPopupOpen = false;
    this.unassignDevicePopupOpen = false;
    this.deviceResetOptionsPopupOpen = false;
    this.deviceFotaPopupOpen = false;
    this.deviceSetEnergyMeterValuePopupOpen = false;
    this.createProjectPopupOpen = false;
    this.deleteProjectPopupOpen = false;
    this.configureDevicePopupOpen = false;
    this.dashboardConfigurationPopupOpen = false;
    this.createDepotPopupOpen = false;
    this.editDepotPopupOpen = false;
    this.deleteDepotPopupOpen = false;
    this.manageParkingSpotsPopupOpen = false;
    this.editProjectPopupOpen = false;
    this.createRoadPopupOpen = false;
    this.editRoadPopupOpen = false;
    this.manageRoadPopupOpen = false;
    this.alertManagementPopupOpen = false;
    this.userAttributesPopupOpen = false;
    this.displayNotificationsPopupOpen = false;
    this.projectSelectionPopupOpen = false;
    this.informationPopup.isOpen = false;
    this.inviteUserPopupOpen = false;
    this.updateUserPopupOpen = false;
    this.userAccountActivationPopupOpen = false;
    this.deleteUserPopupOpen = false;
    this.createFleetPopupOpen = false;
    this.closeCreateDevicePopup();
    this.closeEditDevicePopup();
    this.closeEditPOSPopup();
    this.closeEditAPFCPopup();
    this.closeEditOCPPPopup();
    this.closeAssignOCPPPopup();
    this.closeUnassignDevicePopup();
    this.closeDeviceResetOptionsPopup();
    this.closeCreateProjectPopup();
    this.closeDeleteProjectPopup();
    this.closeConfigureDevicePopup();
    this.closeDashboardConfigurationPopup();
    this.closeCreateDepotPopup();
    this.closeEditDepotPopup();
    this.closeDeleteDepotPopup();
    this.closeManageParkingSpotsPopup();
    this.closeEditProjectPopup();
    this.closeCreateRoadPopup();
    this.closeEditRoadPopup();
    this.closeManageRoadPopup();
    this.closeUserAttributesPopup();
    this.closeChargingSchedulePopup();
    this.closeSdCardDownloadPopup();
    this.closeNotificationsPopup();
    this.closeFirmwareDownloadPopup();
    this.closeProjectSelectionPopup();
    this.hideAlertMessage();
    this.closeInviteUserPopup();
    this.closeUpdateUserPopup();
    this.closeUserAccountActivationPopup();
    this.closeDeleteUserPopup();
    this.closeCreateFleetPopup();
    this.appModalState = defaultModalState;
  };
}

export default PopupStore;
